import React from 'react';
import { Modal, Button } from "react-bootstrap";
import ResourcesModalBody from './ResourcesModalBody';

const ResourcesModel = (model) => {

  // Destructuring Passed Props from Parent
  const { show, close } = model;
  // Destructuring Passed Props from Parent

  return (
    <div>
      <Modal 
        show={show}
        cancel={close}
        className="w-9vw"
      >
        <Modal.Header>
          <h4 className="text-light m-auto text-center">Resources</h4>
          <Button onClick={close}>Close</Button>
        </Modal.Header>

        {/* RESOURCES MODAL BODY COMP */}
        <ResourcesModalBody />
        {/* RESOURCES MODAL BODY COMP */}

      </Modal>
    </div>
  )
}

export default ResourcesModel
import React , { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
//import { useSelector } from "react-redux";
import {BrowserRouter as Router, Route, Routes } from "react-router-dom";


import { loginSuccess } from "./features/login/loginSlice";
import { getUserProfile } from './features/user/userAction';


import './App.css';
import Header from "./components/header/Header";
import Home from './pages/home/Home';
import Dashboard from "./pages/dashboard/Dashboard";
import PageNotFound from './pages/pagenotfound/PageNotFound';
import Landing from './pages/landing/Landing';

function App() {
  // useNavigate is the new version of useHistory
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.login);
  const { user } = useSelector((state) => state.user);
  // const navigate =  useNavigate();

  useEffect(() => {
      !user.id && dispatch(getUserProfile())

      !isAuth && sessionStorage.getItem('authToken') && dispatch(loginSuccess())

  }, [dispatch, isAuth, user.id]);

  // SCROLL TO CONTACT SEC
  const scrollToContact = () => {
    const divElement = document.getElementById('contact');
    divElement.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className="App">
      
      <Router>
        <Header handleScrollContactPage={scrollToContact}/> 
        <Routes>
          <Route exact path="/" element={<Landing handleScrollContactPage={scrollToContact} />} />
          <Route exact path="/login" element={<Home />} />
          { 
            isAuth ? 
                <Route exact path="/dashboard" element={<Dashboard />} />
                : 
                <Route exact path="/" element={<Home />} />
          }
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default App;

import React from 'react'
import { Button } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserChatStatus } from '../../../features/user/userAction';
import Avatar from 'react-avatar';

const ChatPersonList = (props) => {

    const dispatch = useDispatch();

    const { divclass, clickclose, clickopen, sendHandleVisibleDiv, sign_in_user_id, resultchat } = props;
    const { allusers } = useSelector((state)=>state.user);
    const totalMessages = 
        resultchat && 
        resultchat.filter((a) => (
            a.receiver_id === sign_in_user_id  
            &&
            a.read_receipt === '0'
        )).length; 
    
    const handleChatListUserID = (event) => {
        //console.log(event.currentTarget.id);
        dispatch(updateUserChatStatus(event.currentTarget.id));
    }

    return (
        <div className={divclass}>
            <div className='chatHeader d-flex align-items-center justify-content-between bg-secondary rounded-top'>
                <h6 className='text-uppercase text-white ps-2 pr-2 mb-0'>Networking</h6>
                <span className="message_counter">{totalMessages}</span>
                {
                    divclass.includes('open') ?
                        <Button className="no-shadow" onClick={clickclose}><i className="bi bi-chevron-down"></i></Button>
                    :
                        <Button className="no-shadow" onClick={clickopen}><i className="bi bi-chevron-up"></i></Button>
                }
            </div>

            <div className='chatAllUser border-secondary border  border-1'> 
                <Scrollbars
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    autoHeightMin={0}
                    autoHeightMax={200}
                >
                    <ul className="list-unstyled">
                        {allusers && 
                        allusers
                        .filter((a) => a.id !== sign_in_user_id  )
                        .map((row, i) => (
                            row.role === '1' || row.role === '2' || row.role === '3' ?
                            <li 
                                className={
                                        resultchat && 
                                        resultchat.filter((a) => (
                                            a.user_id === row.id
                                            &&
                                            a.receiver_id === sign_in_user_id  
                                            &&
                                            a.read_receipt === '0'
                                        )).length ?
                                        'd-flex mb-0 p-2 position-relative align-items-center justify-content-between border-bottom border-white blinking'
                                        :
                                        'd-flex mb-0 p-2 position-relative align-items-center justify-content-between border-bottom border-white'
                                }
                                key={'userChatBot'+i}
                                id={row.id}
                                onClick={(e) => {
                                    handleChatListUserID(e);
                                    sendHandleVisibleDiv(row.id);
                                }}
                            >   
                                <h6 className='text-dark mb-0'>
                                    <span className='position-relative me-2'>
                                        <i
                                            className=
                                            {
                                                row.chat_user_status === '1' 
                                                ? 
                                                "fst-normal position-relative d-inline-block rounded-circle border border-2 border-success" 
                                                : 
                                                row.chat_user_status === '2' 
                                                ?  
                                                "fst-normal position-relative d-inline-block rounded-circle border border-2 border-warning" 
                                                : 
                                                "fst-normal position-relative d-inline-block rounded-circle border border-2 border-white"
                                            }
                                        >
                                            <Avatar 
                                                name={row.name} 
                                                size="30"
                                                round={true}
                                            />
                                        </i>
                                    </span>
                                    {row.name}
                                </h6>
                            </li>
                            :
                            ''
                            
                        ))}
                    </ul>
                </Scrollbars>
            </div>
        </div>
    )
}

export default ChatPersonList
import axios from "axios";

const rootURL = 'https://expo.salmanaziz.tech/api/';
const loginUrl = rootURL+'login.php';
const userProfileUrl = rootURL+'user.php';
const allUsersUrl = rootURL+'allusers.php';
const singleUserStatusUpdate = rootURL+'singleuserstatusupdate.php';
const singleUserNotePadGet = rootURL+'singleusersnotepadget.php';
const singleUserNotePadPost = rootURL+'singleusersnotepadpost.php';
const allAgendaUrl = rootURL+'allagenda.php';
const singleAgendaTimeSlotRemove = rootURL+'singleagendatimeslotremove.php';
const singleAgendaTimeSlotSpeakerRemove = rootURL+'singleagendatimeslotspeakerremove.php';
const agendaTimeSlotAttend = rootURL+'singleagendatimeattend.php';
const allResourcesUrl = rootURL+'allresources.php';
const singleResourceToAddToWallet = rootURL+'singleresourcetoaddtowallet.php';
const allmeetingURL = rootURL+'allmeeting.php';
const singleInitiateMeetingURL = rootURL+'singleinitiatemeeting.php';
const singleCloseMeetingURL = rootURL+'singleclosemeeting.php';
const allChatURL = rootURL+'allchat.php';
const singleMeetingChatInsertURL = rootURL+'singlemeetingchatinsert.php';
const singleUserAddURL = rootURL+'singleuserinsert.php';
const singleChatIndURL = rootURL+'singlechatforindividual.php';
const singleInsertChatIndURL = rootURL+'singleinsertchatforindividual.php';
const singleChatIndThunkURL = rootURL+'singleinsertchatforindividualthunk.php';
const singleUpdateUserStatusURL = rootURL+'singleupdateuserstatus.php';
const singleUpdateUserStatusTo0URL = rootURL+'singleupdateuserstatusto0.php';
const singleUpdateUserChatStatusURL = rootURL+'singleupdateuserchatstatus.php';
const shareBCardApiURL = rootURL+'singlesharebcard.php';
const sendEmailApiURL = rootURL+'singlesendemail.php';
const allSurveyURL = rootURL+'allsurvey.php';
const sendSurveyURL = rootURL+'singleeventsurvey.php';
const fetchVideoTokenURL = rootURL+'singlemeetingtokendailyco.php';
const logoutUrl = rootURL+'logout.php';

export const userLogin = (frmData) => {
    return new Promise (async (resolve, reject) => {

        try {
            const res= await axios.post(loginUrl, frmData)
            // console.log(res);
            resolve(res.data);

            if(res.data.status === "success"){
                sessionStorage.setItem('authToken', res.data.token);
                //console.log(res.data.token)
            }

        } catch (error) {
            // console.log(error)
            reject(error)
        }

    })
}

export const fetchUser = () => {
    return new Promise (async (resolve, reject) => {

        try {

            const authToken = sessionStorage.getItem('authToken');
            if(!authToken){
                reject("Token not found!")
            }
            
            const res= await axios.get(userProfileUrl, 
                { 
                    headers: {"Authorization" : `${authToken}`} 
                }
            )
            // console.log(res);
            resolve(res.data);

            if(res.data.status === "success"){
                sessionStorage.setItem('authToken', res.data.token);
                //console.log(res.data.token)
            }

        } catch (error) {
            // console.log(error)
            reject(error.message)
        }

    });
};


export const fetchAllUsers = () => {
    return new Promise (async (resolve, reject) => {

        try {

            const authToken = sessionStorage.getItem('authToken');
            if(!authToken){
                reject("Token not found!")
            }
            
            const res= await axios.get(allUsersUrl, 
                { 
                    headers: {"Authorization" : `${authToken}`} 
                }
            )
            // console.log(res);
            resolve(res.data);

            if(res.data.status === "success"){
                sessionStorage.setItem('authToken', res.data.token);
                //console.log(res.data.token)
            }

        } catch (error) {
            // console.log(error)
            reject(error.message)
        }

    });
};

export const statusUpdate = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(singleUserStatusUpdate ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    frmData,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error.message)
            reject(error)
        }
    })
}


export const fetchSingleUsersNotePad = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(singleUserNotePadGet ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}

export const updateNotePadText = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(singleUserNotePadPost ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    frmData,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}

export const fetchAllAgenda = () => {
    return new Promise (async (resolve, reject) => {

        try {

            const authToken = sessionStorage.getItem('authToken');
            if(!authToken){
                reject("Token not found!")
            }
            
            const res= await axios.get(allAgendaUrl, 
                { 
                    headers: {"Authorization" : `${authToken}`} 
                }
            )
            // console.log(res);
            resolve(res.data);

            if(res.data.status === "success"){
                sessionStorage.setItem('authToken', res.data.token);
                //console.log(res.data.token)
            }

        } catch (error) {
            // console.log(error)
            reject(error.message)
        }

    });
};

export const agendaTimeSlotRemove = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(singleAgendaTimeSlotRemove ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    frmData,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}

export const agendaTimeSlotSpeakerRemove = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(singleAgendaTimeSlotSpeakerRemove ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    frmData,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}

export const agendaTimeSlotUserAttendance = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(agendaTimeSlotAttend ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    frmData,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}

export const fetchAllResources = () => {
    return new Promise (async (resolve, reject) => {

        try {

            const authToken = sessionStorage.getItem('authToken');
            if(!authToken){
                reject("Token not found!")
            }
            
            const res= await axios.get(allResourcesUrl, 
                { 
                    headers: {"Authorization" : `${authToken}`} 
                }
            )
            // console.log(res);
            resolve(res.data);

            if(res.data.status === "success"){
                sessionStorage.setItem('authToken', res.data.token);
                //console.log(res.data.token)
            }

        } catch (error) {
            // console.log(error)
            reject(error.message)
        }

    });
};

export const resourceToAddToWallet = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(singleResourceToAddToWallet ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    frmData,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}

export const fetchMeeting = () => {
    return new Promise (async (resolve, reject) => {

        try {

            const authToken = sessionStorage.getItem('authToken');
            if(!authToken){
                reject("Token not found!")
            }
            
            const res= await axios.get(allmeetingURL, 
                { 
                    headers: {"Authorization" : `${authToken}`} 
                }
            )
            // console.log(res);
            resolve(res.data);

            if(res.data.status === "success"){
                sessionStorage.setItem('authToken', res.data.token);
                //console.log(res.data.token)
            }

        } catch (error) {
            // console.log(error)
            reject(error.message)
        }

    });
};

export const initateMeeting = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(singleInitiateMeetingURL ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    frmData,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}

export const closeMeeting = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(singleCloseMeetingURL ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    frmData,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}

export const fetchChat = () => {
    return new Promise (async (resolve, reject) => {

        try {

            const authToken = sessionStorage.getItem('authToken');
            if(!authToken){
                reject("Token not found!")
            }
            
            const res= await axios.get(allChatURL, 
                { 
                    headers: {"Authorization" : `${authToken}`} 
                }
            )
            // console.log(res);
            resolve(res.data);

            if(res.data.status === "success"){
                sessionStorage.setItem('authToken', res.data.token);
                //console.log(res.data.token)
            }

        } catch (error) {
            // console.log(error)
            reject(error.message)
        }

    });
};

export const insertMeetingChat = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(singleMeetingChatInsertURL ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    frmData,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}


export const userInsertAdmin = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(singleUserAddURL ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    frmData,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}


export const fetchIndividualChat = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(singleChatIndURL ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    frmData,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}

export const fetchIndividualChatThunk = () => {
    return new Promise (async (resolve, reject) => {

        try {

            const authToken = sessionStorage.getItem('authToken');
            if(!authToken){
                reject("Token not found!")
            }
            
            const res= await axios.get(singleChatIndThunkURL, 
                { 
                    headers: {"Authorization" : `${authToken}`} 
                }
            )
            // console.log(res);
            resolve(res.data);

            if(res.data.status === "success"){
                sessionStorage.setItem('authToken', res.data.token);
                //console.log(res.data.token)
            }

        } catch (error) {
            // console.log(error)
            reject(error.message)
        }

    });
};


export const userInsertIndChat = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(singleInsertChatIndURL ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    frmData,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}

export const updateUserStatusApi = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(singleUpdateUserStatusURL ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    id,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}

export const updateUserStatusTo0Api = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(singleUpdateUserStatusTo0URL ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    id,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}



export const updateUserChatStatusApi = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(singleUpdateUserChatStatusURL ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    id,
                }
            );
            
            //console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}


export const shareBCardApi = (pid, shareObj) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(shareBCardApiURL ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    pid, shareObj,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}


export const sendEmailApi = (id, formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(sendEmailApiURL ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    id, formData,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}

export const userLogout = async () => {

    return new Promise (async (resolve, reject) => {

        try {
            const authToken = sessionStorage.getItem('authToken');
            if(!authToken){
                console.log("Token not found!")
            }
            const res= await axios.post(logoutUrl,
            { 
                headers: {"Authorization" : `${authToken}`} 
            })
            // console.log(res);
            resolve(res.data);
        } catch (error) {
            // console.log(error);
            reject(error)
        }

    })
    
}


export const fetchSurvey = () => {
    return new Promise (async (resolve, reject) => {

        try {

            const authToken = sessionStorage.getItem('authToken');
            if(!authToken){
                reject("Token not found!")
            }
            
            const res= await axios.get(allSurveyURL, 
                { 
                    headers: {"Authorization" : `${authToken}`} 
                }
            )
            // console.log(res);
            resolve(res.data);

            if(res.data.status === "success"){
                sessionStorage.setItem('authToken', res.data.token);
                //console.log(res.data.token)
            }

        } catch (error) {
            // console.log(error)
            reject(error.message)
        }

    });
}


export const sendSurveyApi = (sObj) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(sendSurveyURL ,
                {
                    headers:{
                        Authorization: sessionStorage.getItem("authToken"),
                    },
                    sObj,
                }
            );
            
            // console.log(result.data);
            resolve(result.data);
        } catch (error) {
            // console.log(error)
            reject(error)
        }
    })
}

export const fetchVideoToken = () => {
    return new Promise (async (resolve, reject) => {

        try {

            const authToken = sessionStorage.getItem('authToken');
            if(!authToken){
                reject("Token not found!")
            }
            
            const res= await axios.get(fetchVideoTokenURL, 
                { 
                    headers: {"Authorization" : `${authToken}`} 
                }
            )
            // console.log(res);
            resolve(res.data);

            if(res.data.status === "success"){
                sessionStorage.setItem('authToken', res.data.token);
                //console.log(res.data.token)
            }

        } catch (error) {
            // console.log(error)
            reject(error.message)
        }

    });
}

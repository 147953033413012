import React from 'react'
import { Alert, Col, Form  } from 'react-bootstrap';

const FormInputHelper = (props) => {

    const { name, label, fvalue, ftype, changeeffect, blureffect, formikerror, formiktouched } = props;

    return (
        <Col className="mb-3" md="4" >
            <Form.Group  controlId="formBasicEmail">
                <Form.Label>{label}*</Form.Label>
                <Form.Control 
                    type={ftype} 
                    placeholder={label}
                    name={name}
                    value={fvalue}
                    onChange={changeeffect}
                    onBlur={blureffect}
                />
                {formikerror && formiktouched ?
                    <Alert variant="danger">{formikerror}</Alert>
                : ""}
            </Form.Group>
        </Col>
    )
}

export default FormInputHelper
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Services.css';

const Services = () => {
  return (
    <section className="services" id="services">
	        <Container className='servicesInner'>
                <Row>
                    <Col>
                        <div className="section-tittle">
                            <h2 className="mb-5">Reasons to Host Your Virtual Event <br /> at ExpoSite </h2>
                        </div>
					 
                        <div className="parallax_panel d-flex align-items-center">
                            <div className="parallax_box">
                                <i className="bi bi-door-open"></i>
                                <div className="right_section">
                                    <h3>Sessions</h3>
                                    <p>Digital Panel Discussions &amp; Industry Presentations</p>
                                </div>
                            </div>
                        
                            <div className="parallax_box">
                                <i className="bi bi-bullseye"></i>
                                <div className="right_section">
                                    <h3>Roundtable</h3>
                                    <p>Conversations and deliberations that are contagious</p>
                                </div>
                            </div>
                        
                            <div className="parallax_box">
                                <i className="bi bi-wifi"></i>
                                <div className="right_section" >
                                    <h3>Network</h3>
                                    <p>Network Digitally with industry leaders across the globe</p>
                                </div>
                            </div>

                            <div className="parallax_box">
                                <i className="bi bi-people"></i>
                                <div className="right_section">
                                    <h3>Exhibition</h3>
                                    <p>Exhibit your products to delegates from across the world.</p>
                                </div>
                            </div>
                            <div className="parallax_box">
                                <i className="bi bi-bar-chart-line"></i>
                                <div className="right_section">
                                    <h3>Live Polling</h3>
                                    <p>Polling and chat is possible while live video conferencing.</p>
                                </div>
                            </div>  
                            <div className="parallax_box">
                                <i className="bi bi-person-bounding-box"></i>
                                <div className="right_section">
                                    <h3>Branded Booths</h3>
                                    <p>Digital experience that mimics the feel of a real-life event.</p>
                                </div>
                            </div>
                            <div className="parallax_box">
                                <i className="bi bi-clipboard2-data"></i>
                                <div className="right_section">
                                    <h3>Reports &amp; Analytics</h3>
                                    <p>Secure platform that generates robust insights and logs data for future analysis.</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
	   
	   
	   </section>
  )
}

export default Services
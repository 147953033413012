import React from 'react'
import { useSelector } from 'react-redux';
import LiveChat from './LiveChat'
import LiveChatForm from './LiveChatForm';


const ChatBot = () => {
  const { user } = useSelector((state)=>state.user);
  return (
    <div className='ChatBot border-1'>
        <LiveChat />
        <LiveChatForm signinuserid={user.id}/>
    </div>
  )
}

export default ChatBot
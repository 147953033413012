import React from 'react';
import { Modal } from "react-bootstrap";

const AgendaSpeakerAddBody = () => {
  

  return (
    <Modal.Body>
        Modal Body
    </Modal.Body>
  )
}


export default AgendaSpeakerAddBody
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import './Footer.css';

export default function Footer() {
  return (
    <footer className="site-footer">
      <Container>
        <Row>
          <Col className="text-center" md={12} >
            <p>
              Copyright © <script>document.write(new Date().getFullYear());</script>2022 All rights reserved | This template is made with &hearts; by <Link rel="noopener noreferrer" target="_blank" to="https://salmanaziz.tech">SalmanAziz</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

import React from 'react';
import { Modal, Button } from "react-bootstrap";
import { useSelector } from 'react-redux';
import AddPersonModalBody from './AddPersonModalBody';

const AddPersonModel = (model) => {

  // Destructuring Passed Props from Parent
  const { show, close} = model;
  // Destructuring Passed Props from Parent
  const { user } = useSelector((state)=>state.user);

  return (
    <div>
      <Modal 
        show={show}
        cancel={close}
        className="w-9vw"
      >
        <Modal.Header>
          <h4 className="text-light m-auto text-center">Add Participant</h4>
          <Button onClick={close}>Close</Button>
        </Modal.Header>

        {/* AGENDA MODAL BODY COMP */}
        <AddPersonModalBody userid={user.id}/>
        {/* AGENDA MODAL BODY COMP */}

      </Modal>
    </div>
  )
}

export default AddPersonModel
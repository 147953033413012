import React, {useEffect, useState} from 'react';
import { Modal, Row, Nav, Col, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers } from "../../../features/user/userAction";
import StatusForm from "./status/StatusForm";
import SingleSendEmail from './singlesendemail/SingleSendEmail';
import SingleBCard from './singlebcard/SingleBCard';

const DashboardModalBody = (props) => {

  const { isAuth } = useSelector((state) => state.login);

  const { handleVisibleDiv, modelClose } = props;

  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(getAllUsers())
  }, [dispatch]);
  
  // We start with an empty list of items.
  const {allusers, user, error} = useSelector((state)=>state.user);
  // BECAUSE OF LOADER IS SHOWING EVERYWHERE
  // if(isLoading) return <h3>Loading ...</h3>;
  if(error) return <h3>{error}</h3>

  // COUNT TOTAL EXHIBITORS
  const totalExhibitors = allusers.filter(row => row.role === "2");
  // COUNT TOTAL VISITORS
  const totalVisitors = allusers.filter(row => row.role === "3");
  // COUNT TOTAL EXHIBITORS & VISITORS (Note: Except Admin)
  const totalExhibitorsVisitors = totalExhibitors.length+totalVisitors.length;

  // INNER MODELS
  const [modalInnerState, setModalInnerState] = useState(false);
  const handleShowModalSendMessage = (id) => { 
    setModalInnerState("singlesendemail-model"+id);
  }
  const handleShowModalSendBCard = (id) => { 
    setModalInnerState("singlebcard-model"+id);
  }
  

  return (
        <Modal.Body>
          <Row>
            <Col sm={4}>
              <div className="bg-light p-2 mb-3 text-dark text-center rounded-2 ">
                <p className="m-0 text-capitalize fw-bolder">Total Exhibitors&nbsp;
                  <span>
                    {totalExhibitors.length}
                  </span>
                </p>				
              </div>
            </Col>
            <Col sm={4}>
              <div className="bg-light p-2 mb-3 text-dark text-center rounded-2 ">
                <p className="m-0 text-capitalize fw-bolder">Total Visitors&nbsp;
                  <span>
                    {totalVisitors.length}
                  </span>
                </p>				
              </div>
            </Col>
            <Col sm={4}>
              <div className="bg-light p-2 mb-3 text-dark text-center rounded-2 ">
                <p className="m-0 text-capitalize fw-bolder">All Members&nbsp;
                  <span>{totalExhibitorsVisitors}</span>
                </p>							
              </div>
            </Col>
          </Row>
                    
          <Tab.Container 
            id="mainTabs" 
            defaultActiveKey=
            {isAuth === true ? 
              user.role === '1' ? 'first' : 'second'
              : ''
            }
          >
            <Row className="dashTabs">
              <Col sm={4}>
                <Nav variant="pills" className="flex-column">
                  {
                      isAuth === true ? 
                          user.role === '1' ? 
                          <>
                            <Nav.Item>
                              <Nav.Link eventKey="first" className="btn text-start ">
                                <p className="text-dark mb-1">Admin</p>
                                <p className="text-dark mb-0">You can have a look to all who are the Admin.</p>
                              </Nav.Link>
                            </Nav.Item>
                            <hr />
                          </>
                          : 
                          ''
                      :
                      ''
                  }
                  <Nav.Item>
                    <Nav.Link eventKey="second" className="btn text-start">
                      <p className="text-dark mb-1">Exhibitors</p>
                      <p className="text-dark mb-0">You can have a look to all who are exhibitors in the events.</p>
                    </Nav.Link>
                  </Nav.Item>
                  <hr />
                  <Nav.Item>
                    <Nav.Link eventKey="third" className="btn text-start">
                      <p className="text-dark mb-1">Visitors</p>
                      <p className="text-dark mb-0">You can have a look to all who are participating in the events.</p>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  {
                      isAuth === true ? 
                          user.role === '1' ? 
                          <Tab.Pane eventKey="first">
                            {/* INNER TABS ADMIN */}
                            <Tab.Container id="innerTabs1" defaultActiveKey="innerAdmin0">
                              <Row>
                                <Col sm={6}>
                                  <div className="bg-light p-2 mb-2 text-dark text-center rounded-2 ">
                                    <p className="m-0 text-capitalize fw-bolder">Admin</p>				
                                  </div>
                                  <Nav variant="pills" className="flex-column">
                                      {
                                        allusers &&
                                          allusers.map((admin, index) => (
                                            admin.role === "1" &&
                                            <Nav.Item key={"adminNav"+index}>
                                              <Nav.Link className="text-dark" eventKey={"innerAdmin"+index}>{admin.name}</Nav.Link>
                                            </Nav.Item>
                                          ))
                                      }
                                  </Nav>
                                </Col>
                                <Col sm={6}>
                                  <div className="bg-light p-2 mb-2 text-dark text-center rounded-2 ">
                                    <p className="m-0 text-capitalize fw-bolder">Admin Details</p>				
                                  </div>
                                  <Tab.Content className="tabDetail">
                                      {
                                        allusers &&
                                          allusers.map((admin, index) => (
                                            admin.role === "1" &&
                                            <Tab.Pane key={"adminDetails"+index} eventKey={"innerAdmin"+index}>
                                              <p className="bg-primary p-2 mb-2 text-white text-center rounded-2 text-capitalize fw-bolder">
                                                {admin.name}
                                              </p>

                                              {/* STATUS UPDATE --> IN CHILD COMPONENT */}
                                              <StatusForm 
                                                  myusersname={admin.name} 
                                                  myusersid={admin.id} 
                                                  myuserislock={admin.islock} 
                                              />
                                              {/* STATUS UPDATE --> IN CHILD COMPONENT */}

                                              <div className="bg-light p-2 mb-3 text-dark text-end rounded-2">
                                                  <ul className="p-0 m-0 d-flex align-items-center justify-content-between list-unstyled ">
                                                    <li 
                                                      className="btn p-0" 
                                                      id={admin.id}
                                                      onClick={(e) => {
                                                        modelClose(e)
                                                        handleVisibleDiv(admin.id);
                                                      }}
                                                    >
                                                      <i className="bi bi-chat-text"></i>
                                                    </li>
                                                    <li 
                                                      className="btn p-0" 
                                                      onClick={() => {
                                                        handleShowModalSendMessage(admin.id);
                                                      }}
                                                    >
                                                      <i className="bi bi-envelope"></i>
                                                    </li>
                                                    <li 
                                                      className="btn p-0"
                                                      onClick={() => {
                                                        handleShowModalSendBCard(admin.id);
                                                      }}
                                                    >
                                                      <i className="bi bi-postcard"></i>
                                                    </li>
                                                  </ul>
                                              </div>
                                              {/* MODALS Starts */}
                                              <SingleSendEmail 
                                                show={modalInnerState === "singlesendemail-model"+admin.id} 
                                                close={() => setModalInnerState(false)} 
                                                myname = {user.name}
                                                myemail = {user.email}
                                                pid={admin.id}
                                                usersname={admin.name} 
                                                usersemail={admin.email}
                                              />
                                              <SingleBCard
                                                show={modalInnerState === "singlebcard-model"+admin.id} 
                                                close={() => setModalInnerState(false)} 
                                                myname = {user.name}
                                                myemail = {user.email}
                                                myjobtitle= {user.jobtitle}
                                                myphone={user.phone}
                                                pid={admin.id}
                                                usersname={admin.name} 
                                                usersemail={admin.email}
                                              />
                                              {/* MODALS Ends */}
                                              <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                                <b className="float-start">Name: </b>{admin.name}
                                              </p>
                                              <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                                <b className="float-start">Company: </b>{admin.company}
                                              </p>
                                              <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                                <b className="float-start">Company Industry: </b>{admin.CompanyIndustry}
                                              </p>
                                              <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                                <b className="float-start">Job Title: </b>{admin.JobTitle}
                                              </p>
                                              <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                                <b className="float-start">Email: </b>{admin.email}
                                              </p>
                                              <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                                <b className="float-start">Country: </b>{admin.country}
                                              </p>
                                              <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                                <b className="float-start">City: </b>{admin.City}
                                              </p>
                                              <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                                <b className="float-start">Job Function: </b>{admin.JobFunction}
                                              </p>
                                            </Tab.Pane>
                                          ))
                                      }
                                  </Tab.Content>
                                </Col>
                              </Row>
                            </Tab.Container>
                          </Tab.Pane>
                          : 
                          ''
                      :
                      ''
                  }
                  
                  <Tab.Pane eventKey="second">
                    {/* INNER TABS ADMIN */}
                    <Tab.Container id="innerTabs1" defaultActiveKey="innerExhibitor2">
                      <Row>
                        <Col sm={6}>
                          <div className="bg-light p-2 mb-2 text-dark text-center rounded-2 ">
                            <p className="m-0 text-capitalize fw-bolder">Exhibitors</p>				
                          </div>
                          <Nav variant="pills" className="flex-column">
                              {
                                allusers &&
                                  allusers.map((exhib, index) => (
                                    exhib.role === "2" &&
                                    <Nav.Item key={"exhibNav"+index}>
                                      <Nav.Link className="text-dark" eventKey={"innerExhibitor"+index}>{exhib.name}</Nav.Link>
                                    </Nav.Item>
                                  ))
                              }
                          </Nav>
                        </Col>
                        <Col sm={6}>
                          <Tab.Content>
                            <div className="bg-light p-2 mb-2 text-dark text-center rounded-2 ">
                              <p className="m-0 text-capitalize fw-bolder">Exhibitors Details</p>				
                            </div>
                              {
                                allusers &&
                                  allusers.map((exhib, index) => (
                                    exhib.role === "2" &&
                                    <Tab.Pane key={"exhibDetails"+index} eventKey={"innerExhibitor"+index}>
                                      <p className="bg-primary p-2 mb-2 text-white text-center rounded-2 text-capitalize fw-bolder">
                                        {exhib.name}
                                      </p>

                                      {/* STATUS UPDATE --> IN CHILD COMPONENT */}
                                      <StatusForm 
                                          myusersname={exhib.name} 
                                          myusersid={exhib.id} 
                                          myuserislock={exhib.islock} 
                                      />
                                      {/* STATUS UPDATE --> IN CHILD COMPONENT */}

                                      <div className="bg-light p-2 mb-3 text-dark text-end rounded-2">
                                          <ul className="p-0 m-0 d-flex align-items-center justify-content-between list-unstyled ">
                                            <li 
                                              className="btn p-0"
                                              id={exhib.id}
                                              onClick={(e) => {
                                                modelClose(e)
                                                handleVisibleDiv(exhib.id);
                                              }}
                                            >
                                              <i className="bi bi-chat-text"></i>
                                            </li>
                                            <li 
                                              className="btn p-0" 
                                              onClick={() => {
                                                handleShowModalSendMessage(exhib.id);
                                              }}
                                            >
                                              <i className="bi bi-envelope"></i>
                                            </li>
                                            <li 
                                              className="btn p-0"
                                              onClick={() => {
                                                handleShowModalSendBCard(exhib.id);
                                              }}
                                            >
                                              <i className="bi bi-postcard"></i>
                                            </li>
                                          </ul>
                                      </div>
                                      {/* MODALS Starts */}
                                      <SingleSendEmail 
                                        show={modalInnerState === "singlesendemail-model"+exhib.id} 
                                        close={() => setModalInnerState(false)} 
                                        myname = {user.name}
                                        myemail = {user.email}
                                        pid={exhib.id}
                                        usersname={exhib.name} 
                                        usersemail={exhib.email}
                                      />
                                      <SingleBCard
                                        show={modalInnerState === "singlebcard-model"+exhib.id} 
                                        close={() => setModalInnerState(false)} 
                                        myname = {user.name}
                                        myemail = {user.email}
                                        myjobtitle= {user.jobtitle}
                                        myphone={user.phone}
                                        pid={exhib.id}
                                        usersname={exhib.name} 
                                        usersemail={exhib.email}
                                      />
                                      {/* MODALS Ends */}
                                      <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                        <b className="float-start">Name: </b>{exhib.name}
                                      </p>
                                      <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                        <b className="float-start">Company: </b>{exhib.company}
                                      </p>
                                      <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                        <b className="float-start">Company Industry: </b>{exhib.CompanyIndustry}
                                      </p>
                                      <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                        <b className="float-start">Job Title: </b>{exhib.JobTitle}
                                      </p>
                                      <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                        <b className="float-start">Email: </b>{exhib.email}
                                      </p>
                                      <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                        <b className="float-start">Country: </b>{exhib.country}
                                      </p>
                                      <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                        <b className="float-start">City: </b>{exhib.City}
                                      </p>
                                      <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                        <b className="float-start">Job Function: </b>{exhib.JobFunction}
                                      </p>
                                    </Tab.Pane>
                                  ))
                              }
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    {/* INNER TABS ADMIN */}
                    <Tab.Container id="innerTabs1" defaultActiveKey="innerVisitor4">
                      <Row>
                        <Col sm={6}>
                          <div className="bg-light p-2 mb-2 text-dark text-center rounded-2 ">
                            <p className="m-0 text-capitalize fw-bolder">Visitor</p>				
                          </div>
                          <Nav variant="pills" className="flex-column">
                              {
                                allusers &&
                                  allusers.map((visitor, index) => (
                                    visitor.role === "3" &&
                                    <Nav.Item key={"visitorNav"+index}>
                                      <Nav.Link className="text-dark" eventKey={"innerVisitor"+index}>{visitor.name}</Nav.Link>
                                    </Nav.Item>
                                  ))
                              }
                          </Nav>
                        </Col>
                        <Col sm={6}>
                          <Tab.Content>
                            <div className="bg-light p-2 mb-2 text-dark text-center rounded-2 ">
                              <p className="m-0 text-capitalize fw-bolder">Visitor Details</p>				
                            </div>
                              {
                                allusers &&
                                  allusers.map((visitor, index) => (
                                    visitor.role === "3" &&
                                    <Tab.Pane key={"visitorDetails"+index} eventKey={"innerVisitor"+index}>
                                      <p className="bg-primary p-2 mb-2 text-white text-center rounded-2 text-capitalize fw-bolder">
                                        {visitor.name}
                                      </p>

                                      {/* STATUS UPDATE --> IN CHILD COMPONENT */}
                                      <StatusForm 
                                          myusersname={visitor.name} 
                                          myusersid={visitor.id} 
                                          myuserislock={visitor.islock} 
                                      />
                                      {/* STATUS UPDATE --> IN CHILD COMPONENT */}

                                      <div className="bg-light p-2 mb-3 text-dark text-end rounded-2">
                                          <ul className="p-0 m-0 d-flex align-items-center justify-content-between list-unstyled ">
                                            <li 
                                              className="btn p-0"
                                              id={visitor.id}
                                              onClick={(e) => {
                                                modelClose(e)
                                                handleVisibleDiv(visitor.id);
                                              }}
                                            >
                                              <i className="bi bi-chat-text"></i>
                                            </li>
                                            <li 
                                              className="btn p-0" 
                                              onClick={() => {
                                                handleShowModalSendMessage(visitor.id);
                                              }}
                                            >
                                              <i className="bi bi-envelope"></i>
                                            </li>
                                            <li 
                                              className="btn p-0"
                                              onClick={() => {
                                                handleShowModalSendBCard(visitor.id);
                                              }}
                                            >
                                              <i className="bi bi-postcard"></i>
                                            </li>
                                          </ul>
                                      </div>
                                      {/* MODALS Starts */}
                                      <SingleSendEmail 
                                        show={modalInnerState === "singlesendemail-model"+visitor.id} 
                                        close={() => setModalInnerState(false)} 
                                        myname = {user.name}
                                        myemail = {user.email}
                                        pid={visitor.id}
                                        usersname={visitor.name} 
                                        usersemail={visitor.email}
                                      />
                                      <SingleBCard
                                        show={modalInnerState === "singlebcard-model"+visitor.id} 
                                        close={() => setModalInnerState(false)} 
                                        myname = {user.name}
                                        myemail = {user.email}
                                        myjobtitle= {user.jobtitle}
                                        myphone={user.phone}
                                        pid={visitor.id}
                                        usersname={visitor.name} 
                                        usersemail={visitor.email}
                                        
                                      />
                                      {/* MODALS Ends */}
                                      <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                        <b className="float-start">Name: </b>{visitor.name}
                                      </p>
                                      <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                        <b className="float-start">Company: </b>{visitor.company}
                                      </p>
                                      <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                        <b className="float-start">Company Industry: </b>{visitor.CompanyIndustry}
                                      </p>
                                      <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                        <b className="float-start">Job Title: </b>{visitor.JobTitle}
                                      </p>
                                      <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                        <b className="float-start">Email: </b>{visitor.email}
                                      </p>
                                      <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                        <b className="float-start">Country: </b>{visitor.country}
                                      </p>
                                      <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                        <b className="float-start">City: </b>{visitor.City}
                                      </p>
                                      <p className="bg-light p-2 mb-10 text-dark text-end rounded-2">
                                        <b className="float-start">Job Function: </b>{visitor.JobFunction}
                                      </p>
                                    </Tab.Pane>
                                  ))
                              }
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
  )
}

export default DashboardModalBody
import React from "react";
import { insertChatPending, insertChatSuccess, insertChatFail } from "../../../../features/user/userSlice";
import { insertMeetingChat } from '../../../../api/userApi';
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getChat } from "../../../../features/user/userAction";


const validationSchema = yup.object({
    messagechat: yup
      .string()
      .min(1, "Please add atleast 3 characters")
      .required("Text is required"),
});

const LiveChatForm = (props) => {

    const {signinuserid} = props;

    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(statusUpdateResetSuccess());
    // }, [dispatch]);

    //const { allchat, error, successMsg } = useSelector(state => state.user);

    const onSubmit = async (values) => {
        
        //const { ...data } = values;
        const userid = values.userid;
        const messagechat = values.messagechat;
        // console.log(email, password);
        dispatch(insertChatPending());

        try {
            const result = await insertMeetingChat({userid, messagechat});
            // console.log(result);
    
            if(result.status === 'error'){
                return dispatch(insertChatFail(result.message));
            }
    
            dispatch(insertChatSuccess(result));
            dispatch(getChat());

        } catch (error) {
            dispatch(insertChatFail(error.message));
        }
    };

    const formik = useFormik({
        initialValues: {
            userid: signinuserid,
            messagechat: '',
        },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
        enableReinitialize: true,
    });

  return (
        <div>
            {/* {error && <Alert variant="danger">{error}</Alert>}
            {successMsg[myusersid] && <Alert variant="success">{successMsg[myusersid]}</Alert>} */}
            {/* {isLoading && <Spinner variant="primary" animation="border" />} */}

            <div>
                <form autoComplete="off" onSubmit={formik.handleSubmit}>
                    <input 
                      type="hidden"
                      name="id"
                      value={formik.values.userid}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <input
                      type="text"
                      name="messagechat"
                      value={formik.values.messagechat}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={formik.errors.messagechat && formik.touched.messagechat ? 'border-danger rounded-start' : 'border-secondary rounded-start'}
                      style={{minHeight:'50px'}}
                    />
                    <button type="submit" className="border-0 text-light outline-transparent bg-primary border-primary rounded-end" style={{minHeight:'50px'}} disabled={!formik.isValid} >Send</button>
                </form>
            </div>
        </div>
  )
}

export default LiveChatForm;
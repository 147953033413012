import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { getChat } from '../../../../features/user/userAction';

const LiveChat = () => {
    const dispatch = useDispatch();
    // We start with an empty list of items.
    const { allchat } = useSelector((state)=>state.user);

    useEffect(() => {
        dispatch(getChat());
    }, [dispatch]);

    return (
        <div className='meetingChat text-start'>
            <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                autoHeightMin={0}
                autoHeightMax={500}
            >
                <ul className='p-2'>
                    {
                        allchat.length ?
                            allchat.map((row, i) =>(
                                <li key={'liveChat'+i}>
                                    <b>{row.name}</b>
                                    <span>{row.message}</span>
                                </li>
                            ))
                        :
                        'No Chat Available'
                    }
                </ul>
            </Scrollbars>
        </div>
  )
}

export default LiveChat
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ContactForm from '../../contactform/ContactForm';
import './Contact.css';

const Contact = () => {
  return (
    <section className='contactSec' id="contact">
        <Container>
            <Row>
                <Col className="offset-md-2" md={8}>
                    <div className='contactDetails'>
                        <h2>Book a Quick Demo for Virtual Event Platform</h2>
                    </div>
                    <ContactForm />
                </Col>
            </Row>
        </Container> 
    </section>
  )
}

export default Contact
import axios from "axios";

const rootURL = 'https://expo.salmanaziz.tech/api/country/';
const allCountryURL = rootURL+'allcountries.php';

export const fetchAllCountries = () => {
    return new Promise (async (resolve, reject) => {

        try {
            
            const res= await axios.get(allCountryURL);
            console.log(res);
            resolve(res.data);

        } catch (error) {
            console.log(error)
            reject(error.message)
        }

    });
};

import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    country:[],
    isLoading: false,
    error: '',
    successMsg: {},
}
const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers:{
        getAllCountryPending: (state)=>{
            state.isLoading = true    
        },
        getAllCountrySuccess: (state, action) => {
            state.isLoading = false
            state.country = action.payload
            state.error = ''
        },
        getAllCountryFail: (state, action) => {
            state.isLoading = false
            state.error = action.payload
        },
    }
})

export const { getAllCountryPending, getAllCountrySuccess, getAllCountryFail } = countrySlice.actions;

export default countrySlice.reducer
import React, { useEffect } from "react";
import { agendaTimeSlotAttendPending, agendaTimeSlotAttendSuccess, agendaTimeSlotAttendFail, agendaTimeSlotAttendResetSuccess } from "../../../features/user/userSlice";
import { agendaTimeSlotUserAttendance } from '../../../api/userApi';
import { useFormik } from "formik";
import { Alert } from "react-bootstrap";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getAllAgenda } from "../../../features/user/userAction";


const validationSchema = yup.object({
    useremail: yup
        .string(),
    agendaid: yup
        .string(),
});


const AgendaFormTimeAttend = (props) => {

    const {useremail, agendaid, ifattend} = props;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(agendaTimeSlotAttendResetSuccess());
    }, [dispatch]);

    const { error, successMsg } = useSelector(state => state.user);

    const onSubmit = async (values) => {
        
        //const { ...data } = values;
        const useremail = values.useremail;
        const agendaid = values.agendaid;
        // console.log(email, password);
        dispatch(agendaTimeSlotAttendPending());

        try {
            const result = await agendaTimeSlotUserAttendance({useremail, agendaid});
            // console.log(result);
    
            if(result.status === 'error'){
                return dispatch(agendaTimeSlotAttendFail(result.message));
            }
    
            dispatch(agendaTimeSlotAttendSuccess(result));
            dispatch(getAllAgenda())

        } catch (error) {
            dispatch(agendaTimeSlotAttendFail(error.message));
        }
    };

    const formik = useFormik({
        initialValues: {
            useremail: useremail,
            agendaid: agendaid,
        },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
        enableReinitialize: true,
    });

    //const attendingdetails = ifattend ? 'Yes' : 'No';

    return (
        <div key={"useragendaattend"+agendaid}>
            {error && <Alert variant="danger">{error}</Alert>}
            {successMsg[agendaid] && <Alert variant="success">{successMsg[agendaid]}</Alert>}
            {/* {isLoading && <Spinner variant="primary" animation="border" />}  */}

            
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <input 
                    type="hidden"
                    name="useremail"
                    value={formik.values.useremail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <input 
                    type="hidden"
                    name="agendaid"
                    value={formik.values.agendaid}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {
                    ifattend === '0' ? 
                    <input type="submit" className='bg-info border-0 rounded-2 p-1 ps-2 pe-2 text-white me-2' value='Will you attend?' disabled={!formik.isValid} />
                    :
                    <input type="submit" className='bg-success border-0 rounded-2 p-1 ps-2 pe-2 text-white me-2' value='You are attending' disabled={!formik.isValid} />
                }
                
            </form>
        </div>
    )
}

export default AgendaFormTimeAttend
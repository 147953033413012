import React from "react";
import { singleMeetingClosePending, singleMeetingCloseSuccess, singleMeetingCloseFail } from "../../../features/user/userSlice";
import { closeMeeting } from '../../../api/userApi';
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";


const validationSchema = yup.object({
    mstatus: yup
        .string(),
});

const CloseMeetingComp = () => {
    
    const dispatch = useDispatch();

    const onSubmit = async (values) => {
        
        const mstatus = values.mstatus;

        dispatch(singleMeetingClosePending());

        try {
            const result = await closeMeeting({mstatus});
            
            if(result.status === 'error'){
                return dispatch(singleMeetingCloseFail(result.message));
            }
    
            dispatch(singleMeetingCloseSuccess(result.message));
            console.log(result.message)

        } catch (error) {
            dispatch(singleMeetingCloseFail(error.message));
        }
    };

    const formik = useFormik({
        initialValues: {
          mstatus: '1',
        },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
        enableReinitialize: true,
    });

    return (
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <input 
                type="hidden"
                name="mstatus"
                value={formik.values.mstatus}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
            <button className="bg-info border-0 text-dark p" type="submit" disabled={!formik.isValid} >
                <i className="bi bi-x"></i>
            </button>
        </form>
    )
}

export default CloseMeetingComp
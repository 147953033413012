import React from 'react';
import { Modal, Button } from "react-bootstrap";
import { useSelector } from 'react-redux';
import EditProfileModelBody from './EditProfileModelBody';

export const EditProfileModel = (model) => {
  // Destructuring Passed Props from Parent
  const { show, close, profile} = model;
  // Call Current Signed In User Details From Redux State
  const { user } = useSelector((state)=>state.user);

  return (
    <div>
      <Modal 
        show={show}
        cancel={close}
        className="w-9vw"
      >
        <Modal.Header>
          <h4 className="text-light m-auto text-center">Edit Profile</h4>
          <Button onClick={close}>Close</Button>
        </Modal.Header>

        {/* TO SHOW EDIT USER DETAILS IN CHILD COMPONENT */}
        <EditProfileModelBody 
          profilemodel={profile} 
          userid={user.id}
          username={user.name}
          useremail={user.email}
          usercountry={user.country}
          usercity={user.city}
          usertitle={user.title}
          userphone={user.phone}
          usermobile={user.mobile}
          usercompany={user.company}
          usercompanyindustry={user.companyindustry}
          userjobtitle={user.jobtitle}
          userjobfunction={user.jobfunction}
          userpobox={user.pobox}
          useracceptterms={user.acceptterms}
        />
        {/* TO SHOW EDIT USER DETAILS IN CHILD COMPONENT */}

      </Modal>
    </div>
  );
};

export default EditProfileModel
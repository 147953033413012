import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { userInsertPending, userInsertSuccess, userInsertFail, userInsertResetSuccess } from '../../../features/user/userSlice';
import { getAllCountries } from '../../../features/country-city/countryAction';
import { userInsertAdmin } from '../../../api/userApi';
import { useFormik } from "formik";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import FormInputHelper from './FormInputHelper';


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object({
    email: yup
        .string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
    name: yup
        .string()
        .required('Please enter your password.')
        .min(1, 'Your password is too short.'),
    password: yup
        .string()
        .required('Please enter your password.')
        .min(5, 'Your password is too short.'),
        //.matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
    rpassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    country: yup
        .string()
        .required("Country is required!"),
    city: yup
        .string()
        .required("City is required!"),
    phone: yup
        .string()
        .required("Phone is required!")
        .matches(phoneRegExp, 'Phone number is not valid')
        .min(8, 'Phone # is too short.'),
    mobile: yup
        .string()
        .required("Phone is required!")
        .matches(phoneRegExp, 'Mobile number is not valid')
        .min(8, 'Mobile # is too short.'),
    company: yup
        .string()
        .required('Please enter your company.')
        .min(1, 'Company is too short.'),
    companyindustry: yup
        .string()
        .required('Please enter your company industry.')
        .min(1, 'Company industry is too short.'),
    jobtitle: yup
        .string()
        .required('Please enter your job title.')
        .min(1, 'Job title is too short.'),
    jobfunction: yup
        .string()
        .required('Please enter your job job function.')
        .min(1, 'Job function is too short.'),
    pobox: yup
        .string()
        .required('Please enter your POBox.')
        .min(1, 'POBox is too short.'),
    acceptterms: yup
        .bool()
        .oneOf([true], 'Accept Terms & Conditions is required'),
});

const AddPersonModalBody = (props) => {
    const { profilemodel, userid, username, useremail, usertitle, userphone, usermobile, usercompany, usercompanyindustry, userjobtitle, userjobfunction, userpobox } = props;

    const dispatch = useDispatch();
    const [cityOptions, setCityOptions] = useState([]);
 
    useEffect(() => {
        dispatch(getAllCountries());
        dispatch(userInsertResetSuccess())
    }, [dispatch]);
    // // We start with an empty list of items.
    const { country} = useSelector((state)=>state.country);
    const { error, successMsg } = useSelector((state)=>state.user);

    

    const onSubmit = async (values) => {
        
        //const { ...data } = values;
        const name = values.name;
        const email = values.email;
        const rpassword = values.rpassword;
        const country = values.country;
        const city = values.city;
        const title = values.title;
        const phone = values.phone;
        const mobile = values.mobile;
        const company = values.company;
        const companyindustry = values.companyindustry;
        const jobtitle = values.jobtitle;
        const jobfunction = values.jobfunction;
        const pobox = values.pobox;
        const acceptterms = values.acceptterms;
        const id = values.id
        
        dispatch(userInsertPending());

        try {
            const result = await userInsertAdmin({title, name, email, rpassword, country, city, phone, mobile, company, companyindustry, jobtitle, jobfunction, pobox, acceptterms, id });
            // console.log(result);
    
            if(result.status === 'error'){
                return dispatch(userInsertFail(result.message));
            }
    
            dispatch(userInsertSuccess(result));
            console.log(result)

        } catch (error) {
            dispatch(userInsertFail(error));
        }
    };

    
    
    
    const formik = useFormik({
        
        initialValues: {
            //name: 'Salman',
            email: useremail,
            name: username,
            title: usertitle,
            city: '',
            phone: userphone,
            mobile: usermobile, 
            company: usercompany, 
            companyindustry: usercompanyindustry, 
            jobtitle: userjobtitle, 
            jobfunction: userjobfunction, 
            pobox: userpobox,
            id: userid,
        },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
        enableReinitialize: true,
    });

    const handleCountryChange = (e) => {
        const c = e.target.value;
        const filteredCities = country.city.filter(city => city.countryid == c);
        setCityOptions(filteredCities);
    }

  return (
        <div className='insertUserWrap pb-4'>
            <Container>
                
                {error && <Alert variant="danger">{error}</Alert>}
                {successMsg[userid] && <Alert variant="success">{successMsg[userid]}</Alert>}
                {/* {isLoading && <Spinner variant="primary" animation="border" />}  */}
                <form autoComplete="off" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Form.Text id="passwordHelpBlock" className="text-dark" muted>
                        *All fields are required
                        </Form.Text>
                        <input type="hidden" name="id" value={formik.values.id} />
                        <FormInputHelper 
                            ftype="email"
                            name="email" 
                            label="Enter unique email"
                            fvalue={formik.values.email}
                            changeeffect={formik.handleChange}
                            blureffect={formik.handleBlur}
                            formikerror={formik.errors.email}
                            formiktouched={formik.touched.email}
                        />
                        <FormInputHelper 
                            ftype="password"
                            name="password" 
                            label="Enter password"
                            fvalue={formik.values.password}
                            changeeffect={formik.handleChange}
                            blureffect={formik.handleBlur}
                            formikerror={formik.errors.password}
                            formiktouched={formik.touched.password}
                        />
                        <FormInputHelper 
                            ftype="password"
                            name="rpassword" 
                            label="Repeat Password"
                            fvalue={formik.values.rpassword}
                            changeeffect={formik.handleChange}
                            blureffect={formik.handleBlur}
                            formikerror={formik.errors.rpassword}
                            formiktouched={formik.touched.rpassword}
                        />
                        <Col className="mb-3" md="4" >
                            <label className="form-label" htmlFor='title'>Select title*</label>
                            <Form.Select 
                                aria-label="Select Title"
                                name="title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}    
                            >
                                <option value="Mr" label="Mr" selected>Mr</option>
                                <option value="Mrs" label="Mrs">Mrs</option>
                                <option value="Dr" label="Dr">Dr</option>
                            </Form.Select>
                        </Col>
                        <FormInputHelper 
                            ftype="text"
                            name="name" 
                            label="Enter name"
                            fvalue={formik.values.name}
                            changeeffect={formik.handleChange}
                            blureffect={formik.handleBlur}
                            formikerror={formik.errors.name}
                            formiktouched={formik.touched.name}
                        />
                        <Col className="mb-3" md="4" >
                            <label className="form-label" htmlFor='country'>Select country*</label>
                            <Form.Select 
                                aria-label="Select Country"
                                className="form-select"
                                name="country"
                                value={formik.values.country}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    handleCountryChange(e);
                                }}
                                //onChange={formik.handleChange}
                                onBlur={formik.handleBlur} 
                            >
                                
                                <option value="" label="Select a country">
                                    Select your country{" "}
                                </option>
                                {
                                    country.all &&
                                    country.all.map((row, i) => (
                                        <option key={'country'+i} value={row.id} label={row.name}>
                                            {row.name}
                                        </option>
                                    ))
                                }
                            </Form.Select>
                            {formik.errors.country && formik.touched.country ?
                                <Alert variant="danger">{formik.errors.country}</Alert>
                            : ""}
                        </Col>
                        <Col className="mb-3" md="4" >
                            <label className="form-label" htmlFor='city'>Select state*</label>
                            <Form.Select 
                                aria-label="Select state"
                                className="form-select"
                                name="city"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                {
                                    cityOptions.map(rcity => (
                                        rcity.cityname
                                    )).length ? 
                                    cityOptions.map(rcity => (
                                        <option 
                                            value={rcity.cityname} 
                                            label={rcity.cityname}
                                        >
                                            {rcity.cityname}
                                        </option>
                                    ))
                                    :
                                    <option value="" label="Select a city">
                                        Select your State{" "}
                                    </option>
                                }
                                
                            </Form.Select>
                            {formik.errors.city && formik.touched.city ?
                                <Alert variant="danger">{formik.errors.city}</Alert>
                            : ""}
                        </Col>
                        <FormInputHelper 
                            ftype="text"
                            name="phone" 
                            label="Enter phone"
                            fvalue={formik.values.phone}
                            changeeffect={formik.handleChange}
                            blureffect={formik.handleBlur}
                            formikerror={formik.errors.phone}
                            formiktouched={formik.touched.phone}
                        />
                        <FormInputHelper 
                            ftype="text"
                            name="mobile" 
                            label="Enter mobile"
                            fvalue={formik.values.mobile}
                            changeeffect={formik.handleChange}
                            blureffect={formik.handleBlur}
                            formikerror={formik.errors.mobile}
                            formiktouched={formik.touched.mobile}
                        />
                        <FormInputHelper 
                            ftype="text"
                            name="company" 
                            label="Enter company"
                            fvalue={formik.values.company}
                            changeeffect={formik.handleChange}
                            blureffect={formik.handleBlur}
                            formikerror={formik.errors.company}
                            formiktouched={formik.touched.company}
                        />
                        <FormInputHelper 
                            ftype="text"
                            name="companyindustry" 
                            label="Enter company industry"
                            fvalue={formik.values.companyindustry}
                            changeeffect={formik.handleChange}
                            blureffect={formik.handleBlur}
                            formikerror={formik.errors.companyindustry}
                            formiktouched={formik.touched.companyindustry}
                        />
                        <FormInputHelper 
                            ftype="text"
                            name="jobtitle" 
                            label="Enter job title"
                            fvalue={formik.values.jobtitle}
                            changeeffect={formik.handleChange}
                            blureffect={formik.handleBlur}
                            formikerror={formik.errors.jobtitle}
                            formiktouched={formik.touched.jobtitle}
                        />
                        <FormInputHelper 
                            ftype="text"
                            name="jobfunction" 
                            label="Enter job function"
                            fvalue={formik.values.jobfunction}
                            changeeffect={formik.handleChange}
                            blureffect={formik.handleBlur}
                            formikerror={formik.errors.jobfunction}
                            formiktouched={formik.touched.jobfunction}
                        />
                        <FormInputHelper 
                            ftype="text"
                            name="pobox" 
                            label="Enter POBox"
                            fvalue={formik.values.pobox}
                            changeeffect={formik.handleChange}
                            blureffect={formik.handleBlur}
                            formikerror={formik.errors.pobox}
                            formiktouched={formik.touched.pobox}
                        />
                        <Col md="4" >
                            <Button type="submit" className='bg-primary border-0 rounded-2 p-1 ps-2 pe-2 text-light w-100' disabled={!formik.isValid}>Add User</Button>
                        </Col>
                    </Row>
                </form>
            </Container>
            <Container>
                <Col md="12" >
                    <Button onClick={profilemodel}>View Profile</Button>
                </Col>
            </Container>
        </div>
  )
}

export default AddPersonModalBody; 
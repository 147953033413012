import moment from 'moment';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useSelector } from 'react-redux';
import ChatBotForm from './ChatBotForm';
import Avatar from 'react-avatar';
//import VideoCallModel from '../videocall/VideoCallModel';

const SinglePersonChat = (props) => {

    const {sendVisibleDivIndex, sendSetVisibleDivIndex, resultchat, sign_in_user_id, chatFrmDashState, chatFrmDashHandle, chatSrollRef } = props;
    const { allusers } = useSelector((state)=>state.user);

    //Video Call
    //const [modalState, setModalState] = useState(false);
    //const handleShowModalVideoCall = () => { setModalState("videocall-model") }

    return (
        <>
            <div className='chatIndividualBox' ref={chatSrollRef}>
                {
                    allusers && 
                    allusers
                    .filter((a) => a.id !== sign_in_user_id  )
                    .map((row, i) => (
                        row.role === '1' || row.role === '2' || row.role === '3' ?
                    <div 
                        className={
                            row.id === sendVisibleDivIndex || row.id === chatFrmDashState ? 
                            "chatPersonMenu open" 
                            : 
                            "chatPersonMenu"
                        }
                        id={row.id}
                        key={'userChatBotPerson'+i}
                    >
                        
                        <div className='chatHeader d-flex align-items-center justify-content-between bg-secondary rounded-top'>
                            <h6 className='d-flex align-items-center text-white mb-0 ps-2 pr-2'>
                                <span className='w-25 position-relative me-2'>
                                    <i
                                        className=
                                        {
                                            row.chat_user_status === '1' 
                                            ? 
                                            "fst-normal position-relative d-inline-block rounded-circle border border-2 border-success" 
                                            : 
                                            row.chat_user_status === '2' 
                                            ?  
                                            "fst-normal position-relative d-inline-block rounded-circle border border-2 border-warning" 
                                            : 
                                            "fst-normal position-relative d-inline-block rounded-circle border border-2 border-white"
                                        }
                                    >
                                        <Avatar 
                                            name={row.name} 
                                            size="25"
                                            round={true}
                                        />
                                    </i>
                                </span>
                                <b className='w-75'>{row.name}</b>
                            </h6>
                            <Button 
                                className="no-shadow" 
                                onClick={(e) => {
                                    // Same Functions from Diff Components
                                    sendSetVisibleDivIndex(e);
                                    chatFrmDashHandle(e);
                                }}
                            >
                                <i className="bi bi-x"></i>
                            </Button>
                        </div>
                        <div className='chatAllUser bg-white border-secondary border  border-1'> 
                            <Scrollbars
                                autoHide
                                autoHideTimeout={1000}
                                autoHideDuration={200}
                                autoHeightMin={0}
                                autoHeightMax={200}
                                autoHeight={true}
                                universal={true}
                                thumbMinSize={30}
                            >
                            
                                <ul className="list-unstyled">
                                {resultchat !== '' ? 
                                    resultchat && 
                                    resultchat.map((rchat, i) => (
                                        sign_in_user_id === rchat.user_id && row.id === rchat.receiver_id 
                                        ||
                                        sign_in_user_id === rchat.receiver_id && row.id === rchat.user_id
                                        ?
                                        <li 
                                            className=
                                            {sign_in_user_id === rchat.user_id ?  
                                                'd-flex mb-0 p-2 position-relative align-items-start myMsg bg-light text-dark' 
                                                : 
                                                'd-flex mb-0 p-2 position-relative align-items-start  bg-secondary text-white'
                                            } 
                                            key={'userIndChat'+i}
                                        >
                                            
                                            {rchat.message}
                                            <small>{moment(rchat.created_at).format("Do MMMM YYYY, h:mm a")}</small>
                                        </li>
                                        
                                        :

                                        ''
                                    )) 
                                : 
                                <li>No Chat Available</li>
                                }
                                </ul>
                            </Scrollbars>
                            <ChatBotForm 
                                myId={sign_in_user_id}
                                whomChattingId={row.id}
                                //btnclick={handleShowModalVideoCall}
                            />
                        </div>
                    </div>
                    :
                    ''
                    
                ))}
            </div>

            {/* <VideoCallModel 
                show={modalState === "videocall-model"} 
                close={() => setModalState(false)} 
            /> */}
        </>
        
    )
}

export default SinglePersonChat
import React from "react";
import { Image } from "react-bootstrap";
import bg from '../../uploads/bg.jpg'


export const Dashboard = () => {
  return (
    <div className="eWrapper">
      <div className="ewrapInner">
        <Image src={bg} alt="Expo Site"  fluid />	
      </div>
    </div>
  );
};

export default Dashboard
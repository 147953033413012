import React from 'react';
import { Modal, Button } from "react-bootstrap";
import AgendaSpeakerAddBody from './AgendaSpeakerAddBody';
//import AgendaModalBody from './AgendaModalBody';

const AgendaSpeakerAdd = (model) => {

  // Destructuring Passed Props from Parent
  const { show, close } = model;
  // Destructuring Passed Props from Parent

  return (
    <div>
      <Modal 
        show={show}
        cancel={close}
        className="w-9vw"
      >
        <Modal.Header>
          <h4 className="text-light m-auto text-center">Speaker Add</h4>
          <Button onClick={close}>Close</Button>
        </Modal.Header>

        {/* AGENDA MODAL BODY COMP */}
        <AgendaSpeakerAddBody />
        
        {/* AGENDA MODAL BODY COMP */}

      </Modal>
    </div>
  )
}

export default AgendaSpeakerAdd
import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import SideMenuLi from './SideMenuLi';
import SideMenuInitiateMeeting from './SideMenuInitiateMeeting';
import AddPersonModel from '../modals/addperson/AddPersonModel';
import AgendaModel from '../modals/agenda/AgendaModel';
import AgendaSpeakerAdd from '../modals/agenda/AgendaSpeakerAdd';
import DashboardModel from '../modals/dashboard/DashboardModel';
import NotePadComp from '../modals/notepad/NotePadComp';
import ResourcesModel from '../modals/resources/ResourcesModel';
// import ChatRoomModel from '../modals/chatroom/ChatRoomModel';
import ProfileModel from '../modals/profile/ProfileModel';
import EditProfileModel from '../modals/editprofile/EditProfileModel';
import SurveyModel from '../modals/survey/SurveyModel';
import VideoCallModel from '../modals/videocall/VideoCallModel';
import './SideBar.css';
import InitiateMeeting from '../modals/intiatemeeting/InitiateMeeting';
import ChatMain from '../modals/chat/ChatMain';
import Counter from './Counter';




export default function SideBar({logoutprops}) {

    const { isAuth } = useSelector((state) => state.login);
    const { user } = useSelector((state) => state.user);

    // Navigation Left Side Menu
    const [isOpen, setIsOpen] = useState(false);
    const handleSideMenu = () => {
        setIsOpen(current => !current);
    }

    // NotePad Right Side Menu
    const [notepadState, setNotepadState] = useState(false);
    const handleNotePadMenu = () => {
        setNotepadState(current => !current);
    }

    // All Chat Bottom Menu
    const [chatState, setChatState] = useState(false);
    const handleChatMenu = () => {
        setChatState(current => !current);
    }

    // Scroll To Bottom
    const chatContainer = React.createRef();

    // Individaul User CHAT Menu
    const [chatVisibleDivIndex, setChatVisibleDivIndex] = useState('');
    // const handleChatVisibleDiv = (id) => (
    //     setChatVisibleDivIndex(id),
    //     () => chatContainer.scrollToMyRef()
    // )
    const handleChatVisibleDiv = (id) => {
        setChatVisibleDivIndex(id);
    }
   
    // Models
    const [modalState, setModalState] = useState(false);
    
    const handleShowModalAddPerson = () => { setModalState("addperson-model") }
    const handleShowModalProfile = () => { setModalState("profile-model"); }
    const handleShowModalEditProfile = () => { setModalState("editprofile-model");}
    const handleShowModalAgenda = () => { setModalState("agenda-model") }
    const handleShowModalAddAgendaSpeaker = () => { setModalState("add-agenda-speaker-model") }
    const handleShowModalDashboard = () => { setModalState("dashboard-model") }
    const handleShowModalResources = () => { setModalState("resources-model") }
    const handleShowModalSurvey = () => { setModalState("survey-model") }
    // const handleShowModalChatRoom = () => { setModalState("chatroom-model") }
    const handleShowModalVideoCall = () => { setModalState("videocall-model") }

    return (
        <div>
            <div className={isOpen ? 'sideMenuOverlay open' : 'sideMenuOverlay'}>
                <div className="menu_list">
                    <Scrollbars
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                        autoHeightMin={0}
                        autoHeightMax={500}
                    >
                        <ul>
                            {
                                isAuth === true ? 
                                    user.role === '1' ? 
                                    <>
                                        <SideMenuInitiateMeeting
                                            icon="bi bi-person-video2"
                                            name={<>Initiate Meeting</>}
                                        />
                                        <SideMenuLi
                                            click={handleShowModalAddPerson}
                                            icon="bi bi-person-plus-fill"
                                            name={<>Add Speaker or<br/> Participant</>}
                                        />
                                    </>
                                    : 
                                    ''
                                :
                                ''
                            }
                            
                            
                            <SideMenuLi
                                click={handleShowModalProfile}
                                icon="bi bi-person-circle"
                                name={<>My <br/> Profile</>}
                            />
                            <SideMenuLi
                                click={handleChatMenu}
                                icon="bi bi-bell"
                                name={<>My <br/> Messages</>}
                                ifSpan={<Counter id="notifications_counter" />}
                            />
                            <SideMenuLi
                                click={handleShowModalAgenda}
                                icon="bi bi-grid-fill"
                                name={<>Agenda</>}
                            />
                            <SideMenuLi
                                click={handleShowModalDashboard}
                                icon="bi bi-laptop"
                                name={<>Dashboard</>}
                            />
                            <SideMenuLi
                                click={handleNotePadMenu}
                                icon="bi bi-clipboard-minus"
                                name={<>Note Pad</>}
                            />
                            <SideMenuLi
                                click={handleShowModalResources}
                                icon="bi bi-gear-wide"
                                name={<>Resources<br /> Center</>}
                            />
                            <SideMenuLi
                                click={handleShowModalSurvey}
                                icon="bi bi-collection"
                                name={<>Event <br />Survey</>}
                            />
                            <SideMenuLi
                                click={handleChatMenu}
                                icon="bi bi-menu-down"
                                name={<>Send<br />Message</>}
                            />
                            <SideMenuLi
                                click={handleChatMenu}
                                icon="bi bi-chat-left-text"
                                name={<>Networking</>}
                            />
                            <SideMenuLi
                                click={handleShowModalVideoCall}
                                icon="bi bi-file-person"
                                name={<>Video Call</>}
                            />
                            <SideMenuLi
                                click={logoutprops}
                                icon="bi bi-power"
                                name={<>Logout</>}
                            />
                        </ul>
                    </Scrollbars>
                    <span onClick={handleSideMenu} className="menu_btn"><i className="bi bi-arrow-right"></i></span>
                </div>
            </div>

            {/* MODALS */}
            <InitiateMeeting />
            <AddPersonModel 
                show={modalState === "addperson-model"} 
                close={() => setModalState(false)} 
            />
            <ProfileModel 
                show={modalState === "profile-model"} 
                close={() => setModalState(false)} 
                editprofile={handleShowModalEditProfile} 
            />
            <EditProfileModel 
                show={modalState === "editprofile-model"} 
                close={() => setModalState(false)} 
                profile={handleShowModalProfile}
            />
            <AgendaModel 
                show={modalState === "agenda-model"} 
                close={() => setModalState(false)} 
                clickevent={handleShowModalAddAgendaSpeaker}
            />
            <AgendaSpeakerAdd 
                show={modalState === "add-agenda-speaker-model"} 
                close={() => setModalState(false)}  
                openagendamodel={handleShowModalAgenda}
            />
            <DashboardModel 
                show={modalState === "dashboard-model"} 
                close={() => setModalState(false)}  
                counter={0}
                sendHandleVisibleDiv={handleChatVisibleDiv}
            />
            <NotePadComp 
                myclass={notepadState ? 'sideMenuRight open' : 'sideMenuRight'} 
                close={() => setNotepadState(false)}
            />
            <ChatMain 
                myclass={chatState ? 'chatMenuBottom open' : 'chatMenuBottom'} 
                close={() => setChatState(false)}
                open={() => setChatState(true)}
                fromDashChatHandle = {handleChatVisibleDiv}
                setFromDashChatHandle={chatVisibleDivIndex}
                chatSrollRef={chatContainer}
            />
            <ResourcesModel 
                show={modalState === "resources-model"} 
                close={() => setModalState(false)} 
            />
            <SurveyModel 
                show={modalState === "survey-model"} 
                close={() => setModalState(false)} 
            />
            <VideoCallModel 
                show={modalState === "videocall-model"} 
                close={() => setModalState(false)} 
            />
            {/* 
            <ChatRoomModel show={modalState === "chatroom-model"} close={() => setModalState(false)} />
            
            {/* {
                allusers &&
                    allusers.map((admin) => (
                    admin.role === "1" &&
                        <SingleSendMessge key={"adminModels"+admin.id}
                            show={modalState === "singlesendmessage-model"+admin.id} 
                            close={() => setModalState(false)} 
                        />
                    ))
            } */}
        </div>
    )
}

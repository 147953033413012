import React from "react";
// import React, { useRef, useEffect } from "react";
// import DailyIframe from '@daily-co/daily-js';
// import './VideoCallMain.css';
// import { useDispatch, useSelector } from "react-redux";
// import { getVideoToken } from "../../../features/user/userAction";
// import { Alert, Spinner } from "react-bootstrap";


const VideoCallMain = () => {
  // const dispatch = useDispatch();
  // useEffect(() => {

  //   dispatch(getVideoToken())

  // }, [dispatch]);
  // const { isLoading, error, user, vtoken } = useSelector(state => state.user);
  // const myname = user.name;
  // const roomname = 'room2';
  // const room = `https://expoapp.daily.co/${roomname}?t=`;
  //const iframeRef = useRef();
  

  // useEffect(() => {
  //   //dispatch(getVideoToken());

  //   // if (!room) {
  //   //   console.error("please set an url!");
  //   //   return;
  //   // };
  //   const daily = DailyIframe.wrap(iframeRef.current, {
  //     showLeaveButton: true,
  //   });
  //   daily.on('joining-meeting', (event) => {
  //       console.log('joining-meeting event', event);
  //   })
  //   .on('joined-meeting', (event) => {
  //       console.log('joined-meeting event', event);
  //       daily.iframe().style.visibility = 'visible';
  //   })
  //   .on('participant-counts-updated', (event) => {
  //       console.log('participant count updated');
  //   })
  //   daily.on('left-meeting', (event) => {
  //       console.log('left-meeting event', event);
  //   }) 
  //   daily.join({ 
  //     url: `https://expoapp.daily.co/${vtoken.room}?t=`, 
  //     userName:myname, 
  //     token: '"'+vtoken.token+'"',
  //   });
    
  // }, []);

  return (
    <>
    {/* {isLoading && <Spinner variant="primary" animation="border" />}
            {error && <Alert variant="danger">{error}</Alert>} 
    {"https://expoapp.daily.co/"+vtoken.room+"?t="+vtoken.token} */}
    {/* <iframe
      className='myFrame'
      title="video call iframe"
      ref={iframeRef}
      allow="camera; microphone; fullscreen"
    ></iframe> */}
    <h1>Coming Soon!</h1>
    </>
    
  );
}

export default VideoCallMain
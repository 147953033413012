import React from "react";
import './Landing.css';
import Banner from "../../components/home/banner/Banner";
import Services from "../../components/home/services/Services";
import Contact from "../../components/home/contact/Contact";
import bimg from '../../uploads/exposite.png';
import Features from "../../components/home/features/Features";
import Subscribe from '../../components/homesubscribe/Subscribe.Comp';
import Footer from "../../components/footer/Footer";


const Landing = (props) => {

  const { handleScrollContactPage } = props;

  return (
    <div className="landing-page" >
      <Banner 
        span='VIRTUAL EVENT PLATFORM'
        h1='Time to go digital and engage with your target audiences virtually'
        p='We are here to create a virtual experience that your attendees will remember. An all-in-one platform that offers robust virtual event hosting features including ticket sales, live streaming, live chat &amp; polling, virtual exhibitor booths, breakout sessions, A.I. powered networking, gamification, and more.'
        href='#contact'
        img={bimg}
        btnHandleReq={handleScrollContactPage}
      />
      <Services />
      <Features 
        h1='Create your captivating virtual event and an innovative experience for visitors'
        p='Virtual Event Corridors are the sections where the exhibition hall, auditorium, resources, lounge and agenda of the virtual event. It is the Main Landing Page of the event.'
        href='#contact'
        img={bimg}
        btnHandleReq={handleScrollContactPage}
      />
      <Subscribe />
      <Contact />
      <Footer />
    </div>
  )
}

export default Landing
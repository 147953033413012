import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginSuccess, logoutSuccess } from "../../features/login/loginSlice";
import { getUserProfile } from '../../features/user/userAction';
import './Header.css'
import { Container, NavDropdown, Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap'
import { userLogout } from "../../api/userApi";
import { Link } from 'react-router-dom';
import SideBar from '../sidebar/SideBar';


export default function Header(props) {

    const { handleScrollContactPage } = props;

    // useNavigate is the new version of useHistory
    const dispatch = useDispatch();
    const { isAuth } = useSelector((state) => state.login);
    const { user } = useSelector((state) => state.user);
    const navigate =  useNavigate();

    useEffect(() => {
        !user.id && dispatch(getUserProfile())
        !isAuth && sessionStorage.getItem('authToken') && dispatch(loginSuccess())

    }, [dispatch, isAuth, user.id]);


    //console.log(isAuth);

    // logout function
    const logMeOut = () => {
        userLogout().then(()=>{
            sessionStorage.removeItem('authToken');
            dispatch(logoutSuccess());
            navigate('/');
        });
    }

    // On click Scroll to ID
    const scrollToServices = () => {
        const divElement = document.getElementById('services');
        divElement.scrollIntoView({ behavior: 'smooth' });
    }
    const scrollToFeatures = () => {
        const divElement = document.getElementById('features');
        divElement.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <>
    <Navbar collapseOnSelect expand="lg" bg="bg-white" variant="light">
        <Container fluid>
            <Navbar.Brand>
                <LinkContainer to="/">
                    <Nav.Link>ExpoSite</Nav.Link>
                </LinkContainer>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto"></Nav>
                <Nav>
                    <LinkContainer to="/">
                        <Nav.Link>Home</Nav.Link>
                    </LinkContainer>
                    <LinkContainer 
                        onClick={scrollToServices} 
                        to="#services"
                    >
                        <Nav.Link>Services</Nav.Link>
                    </LinkContainer>
                    <LinkContainer 
                        onClick={scrollToFeatures} 
                        to="#features"
                    >
                        <Nav.Link>Features</Nav.Link>
                    </LinkContainer>
                    <LinkContainer 
                        onClick={handleScrollContactPage}
                        to="#contact"
                    >
                        <Nav.Link>Contact</Nav.Link>
                    </LinkContainer>
                    {
                        isAuth === true ? 
                        '' 
                        : 
                        <LinkContainer to="login">
                            <Nav.Link>Login</Nav.Link>
                        </LinkContainer>
                    }
                    
                    {
                        isAuth === true ? 
                            // user.role === '0' ? 
                                <NavDropdown title={user.name} id="collasible-nav-dropdown">
                                    <NavDropdown.Item as={Link} to="Dashboard">
                                        Dashboard
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="Profile">
                                        Profile
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={logMeOut}>Logout</NavDropdown.Item>
                                </NavDropdown>
                                // :
                                // <NavDropdown title={user.name} id="collasible-nav-dropdown">
                                //     <NavDropdown.Item as={Link} to="Profile">
                                //         Profile
                                //     </NavDropdown.Item>
                                //     <NavDropdown.Divider />
                                //     <NavDropdown.Item onClick={logMeOut}>Logout</NavDropdown.Item>
                                // </NavDropdown>
                        : 
                        ''
                    }
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
    { 
          isAuth ? 
            // user.role === '0' ? 
            <SideBar logoutprops={logMeOut}/>
            // : 
            // ''
          : 
          ''
        }
    
</>
  )
}

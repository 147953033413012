import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Button} from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { getUsersNotePad } from "../../../features/user/userAction";
import { updateNodePadTextPending, updateNodePadTextSuccess, updateNodePadTextFail, updateNodePadTextResetSuccess } from "../../../features/user/userSlice";
import { updateNotePadText } from '../../../api/userApi';


const validationSchema = yup.object({
  text: yup
      .string()
      .min(1, "Please add atleast 3 characters")
      .required("Text is required"),
  id: yup
      .string()
      .min(1, "Please add atleast 3 characters")
      .required("Text is required"),
  email: yup
      .string()
      .min(1, "Please add atleast 3 characters")
      .required("Text is required"),
});

const NotePadComp = (props) => {

  const {myclass, close} = props;

  // We start with an empty list of items.
  const { notepad, successMsg, error } = useSelector((state)=>state.user);

  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(getUsersNotePad());
    dispatch(updateNodePadTextResetSuccess());
  }, [dispatch]);

  const onSubmit = async (values) => {
    //const { ...data } = values;
    const text = values.text;
    const id = values.id;
    const email = values.email;
    
    dispatch(updateNodePadTextPending());

    try {
        const result = await updateNotePadText({text, id, email});
        console.log(result);

        if(result.status === 'error'){
            return dispatch(updateNodePadTextFail(result.message));
        }
        
        //dispatch(getUsersNotePad());
        dispatch(updateNodePadTextSuccess(result));
        

    } catch (error) {
        dispatch(updateNodePadTextFail(error.message));
    }
  };

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
          text: notepad.text,
          id: notepad.noteid,
          email: notepad.user_email,
      },
      validateOnBlur: true,
      validationSchema: validationSchema,
      onSubmit,
  });

  return (
    <div className={myclass}>
      <div className='d-flex align-items-end justify-content-between'>
        <h3 className='text-white m-0'>MY NOTES</h3>
        <Button onClick={close}><i className="bi bi-x"></i></Button>
      </div>

      <div className='mt-3 notepadWrap text-center'>

            {error && <Alert variant="danger">{error}</Alert>}
            {successMsg[notepad.noteid] && <Alert variant="success">{successMsg[notepad.noteid]}</Alert>}

            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <textarea
                  name="text"
                  value={formik.values.text}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter Token here..."
                  className="mt-3 d-block w-100 border-0 rounded-2 p-2"
                  style={{minHeight: "120px"}}
                ></textarea>
                {formik.errors.text && formik.touched.text ?
                <Alert variant="danger">{formik.errors.text}</Alert>
                : ""}
                <input
                  type="hidden"
                  name="id"
                  value={formik.values.id}
                />
                <input
                  type="hidden"
                  name="email"
                  value={formik.values.email}
                />

                <Button className="mt-2 d-block w-100 btn btn-primary" type="submit" disabled={!formik.isValid}><i className="bi bi-send"></i></Button>
                {/* {isLoading && <Spinner variant="primary" animation="border" />} */}
            </form>
        </div>
    </div>
  )
}

export default NotePadComp
import React from 'react';
import './Banner.css';
import { Col, Container, Row, Image } from 'react-bootstrap';


const Banner = (props) => {
    const {span, h1, p, href, img, btnHandleReq} = props;

  return (
    <div className='Banner'>
        <Container>
            <Row>
                <Col md={6}>
                    <Image src={img} alt="Expo Site"  fluid />	
                </Col>
                <Col md={6}>
                    <div class="hero__caption">
                        <span>{span}</span>
                        <h1>{h1}</h1>
                        <p>{p}</p>
                        <div class="slider-btns">
                            <button to={href} className='btn bg-dark text-white' onClick={btnHandleReq}>Request a Demo</button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Banner
import React from "react";
import { Modal, Button } from "react-bootstrap";
import DashboardModalBody from './DashboardModalBody';
// import { getAllUsers } from "../../../features/user/userAction";
// import SingleSendMessge from './singlesendmessage/SingleSendMessage';
// import SingleSendEmail from './singlesendemail/SingleSendEmail';
// import SingleBCard from './singlebcard/SingleBCard';


const DashboardModel = (props) => {

  const {show, close, sendHandleVisibleDiv} = props;

  return (
    <div>
      <Modal 
        show={show}
        cancel={close}
        className="w-9vw"
      >
        <Modal.Header>
          <h4 className="text-light m-auto text-center">Dashboard</h4>
          <Button onClick={close}>Close</Button>
        </Modal.Header>
        <DashboardModalBody handleVisibleDiv={sendHandleVisibleDiv} modelClose={close}/>
      </Modal>
    </div>
  )
}

export default DashboardModel;
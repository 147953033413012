import React, { useEffect } from 'react';
import { Modal, Button, Alert } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
// import { EditorState } from 'draft-js';
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { sendEmailResetSuccess } from '../../../../features/user/userSlice';
import { sendEmail } from '../../../../features/user/userAction';
import { useDispatch, useSelector } from 'react-redux';


const validationSchema = yup.object({
  text: yup
    .string()
    .min(1, "Please add atleast 3 characters")
    .required("Text is required"),
});


const SingleSendEmail = (props) => {

  const { show, close, pid, myname, myemail, usersname, usersemail } = props;
  const dispatch = useDispatch();

  const { error, successEmail } = useSelector(state => state.user);

  // const [editorState, setEditorState] = useState(() =>
  //   EditorState.createEmpty(),
  // );

  useEffect(() => {
    dispatch(sendEmailResetSuccess())
  }, [dispatch])
  

  const onSubmit = async (values) => {

    //const { ...data } = values;
    const id = values.id;
    const formData = {
      mname : values.mname,
      memail : values.memail,
      uname : values.uname,
      uemail : values.uemail,
      text: values.text,
    }
    
    // console.log(email, password);
    dispatch(sendEmail( id, formData ));

  };

  const formik = useFormik({
    initialValues: {
        id: pid,
        mname: myname,
        memail: myemail,
        uname: usersname,
        uemail: usersemail,
        text: '',
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit,
    enableReinitialize: true,

});

  return (
    <div className={'sMessageWrapper'+pid}>
      <Modal 
        show={show}
        cancel={close}
      >
        <Modal.Header>
          <h5 className="text-dark m-auto text-center">Send An Email to {usersname}</h5>
          <Button onClick={close}>Close</Button>
        </Modal.Header>

        <div className='p-3'>
          
          {error && <Alert variant="danger">{error}</Alert>}
          {successEmail[pid] && <Alert variant="success">{successEmail[pid]}</Alert>} 
          {/* {isLoading && <Spinner variant="primary" animation="border" />} */}

          <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <input  type="hidden" name="id" value={formik.values.id} />
              <input className='w-100 rounded-2 border-0 p-2' type="email" name="mname" value={formik.values.uemail} readOnly/>
              {/* <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                name="text"
                 //value={formik.values.text}
                onChange={(val) => formik.setEditorState("text", val)}
                // onBlur={formik.handleBlur}
                //onEditorStateChange={this.onEditorStateChange}
              /> */}
              <textarea
                name="text"
               value={formik.values.text}
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                placeholder="Enter Email here..."
                className="mt-3 d-block w-100 border-0 rounded-2 p-2 mb-3"
                style={{minHeight: "120px"}}
              ></textarea>
              {formik.errors.text && formik.touched.text ?
                <Alert variant="danger">{formik.errors.text}</Alert>
              : ""}
              <Button className="text-white" type="submit" disabled={!formik.isValid} >
                  Share to <b>{usersname}</b>
              </Button>
          </form>
        </div>
      </Modal>
    </div>
  )
}

export default SingleSendEmail

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { delAgendaTimeSlotSpeakerPending, delAgendaTimeSlotSpeakerSuccess, delAgendaTimeSlotSpeakerFail, delAgendaTimeSlotSpeakerResetSuccess } from "../../../features/user/userSlice";
import { agendaTimeSlotSpeakerRemove } from '../../../api/userApi';
import { useFormik } from "formik";
import { Alert, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getAllAgenda } from "../../../features/user/userAction";


const validationSchema = yup.object({
    id: yup
        .string(),
    email: yup
        .string(),
});

const AgendaFormSpeakerDel = (props) => {

    const {semail, sid, sboxclose} = props;
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(delAgendaTimeSlotSpeakerResetSuccess());
    }, [dispatch]);

    const { error, successMsg, isLoading } = useSelector(state => state.user);

    const onSubmit = async (values) => {
        
        //const { ...data } = values;
        const id = values.id;
        const email = values.email;
        // console.log(email, password);
        dispatch(delAgendaTimeSlotSpeakerPending());

        try {
            const result = await agendaTimeSlotSpeakerRemove({id, email});
            // console.log(result);
    
            if(result.status === 'error'){
                return dispatch(delAgendaTimeSlotSpeakerFail(result.message));
            }
    
            dispatch(delAgendaTimeSlotSpeakerSuccess(result));
            dispatch(getAllAgenda())

        } catch (error) {
            dispatch(delAgendaTimeSlotSpeakerFail(error.message));
        }
    };

    const formik = useFormik({
        initialValues: {
            id: sid,
            email: semail,
        },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
        enableReinitialize: true,
    });

    return (
        <div key={"timespeakers"+sid}>
            {error && <Alert variant="danger">{error}</Alert>}
            {successMsg[sid] && <Alert variant="success">{successMsg[sid]}</Alert>}
            {isLoading && <Spinner variant="primary" animation="border" />} 
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <input 
                    type="hidden"
                    name="id"
                    value={formik.values.id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <input 
                    type="hidden"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <input type="submit" className='bg-error border-0 rounded-2 p-1 ps-2 pe-2 text-dark me-2' value="Yes" disabled={!formik.isValid} />
                <Link 
                    className='btn bg-success border-0 rounded-2 p-1 ps-2 pe-2 text-white'
                    onClick={sboxclose}
                    to="#!">
                    No
                </Link>
            </form>
        </div>
    )
}

export default AgendaFormSpeakerDel
import { getAllCountryPending, getAllCountrySuccess, getAllCountryFail } from './countrySlice';
import { fetchAllCountries } from './countryApi';


export const getAllCountries = () => async (dispatch) => {
    try {
        dispatch(getAllCountryPending());
        //Call the API
        const result = await fetchAllCountries();
        console.log(result);
        
        if(result)
            return dispatch(getAllCountrySuccess(result));

        dispatch(getAllCountryFail("Countries is not found"));
    } catch (error) {
        dispatch(getAllCountryFail(error));
    }
}

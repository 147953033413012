import React from 'react';
import './Features.css';
import { Col, Container, Row, Image } from 'react-bootstrap';


const Features = (props) => {
    const {span, h1, p, href, img, btnHandleReq } = props;

  return (
    <div className='Banner' id="features">
        <Container>
            <Row>
                <Col md={6}>
                    <div class="hero__caption">
                        <span>{span}</span>
                        <h1>{h1}</h1>
                        <p>{p}</p>
                        <div>
                            <ul className='ps-3'>
                                <li>Interactive and graphic rich session experience</li>
                                <li>Wide variety of user spaces</li>
                                <li>Visit Premium Booth & network digitally</li>
                                <li>Share experience on social walls</li>
                            </ul>
                        </div>
                        <div class="slider-btns">
                            <button onClick={btnHandleReq} to={href} className='btn bg-dark text-white'>Request a Demo</button>
                        </div>
                        
                    </div>
                </Col>
                <Col md={6}>
                    <Image src={img} alt="Expo Site"  fluid />	
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Features
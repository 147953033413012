import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { delAgendaTimeSlotPending, delAgendaTimeSlotSuccess, delAgendaTimeSlotFail, delAgendaTimeSlotResetSuccess } from "../../../features/user/userSlice";
import { agendaTimeSlotRemove } from '../../../api/userApi';
import { useFormik } from "formik";
import { Alert, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getAllAgenda } from "../../../features/user/userAction";


const validationSchema = yup.object({
    id: yup
        .string(),
});

const AgendaFormTimeSlot = (props) => {

    const {ivalue, clicke} = props;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(delAgendaTimeSlotResetSuccess());
    }, [dispatch]);

    const { error, successMsg, isLoading } = useSelector(state => state.user);

    const onSubmit = async (values) => {
        
        //const { ...data } = values;
        const id = values.id;
        // console.log(email, password);
        dispatch(delAgendaTimeSlotPending());

        try {
            const result = await agendaTimeSlotRemove({id});
            // console.log(result);
    
            if(result.status === 'error'){
                return dispatch(delAgendaTimeSlotFail(result.message));
            }
    
            dispatch(delAgendaTimeSlotSuccess(result));
            dispatch(getAllAgenda())

        } catch (error) {
            dispatch(delAgendaTimeSlotFail(error.message));
        }
    };

    const formik = useFormik({
        initialValues: {
            id: ivalue,
        },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
        enableReinitialize: true,
    });

  return (
        <div key={"usermain"+ivalue}>
            {error && <Alert variant="danger">{error}</Alert>}
            {successMsg[ivalue] && <Alert variant="success">{successMsg[ivalue]}</Alert>}
            {isLoading && <Spinner variant="primary" animation="border" />} 
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <input 
                    type="hidden"
                    name="id"
                    value={formik.values.id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                    <input type="submit" className='bg-error border-0 rounded-2 p-1 ps-2 pe-2 text-dark me-2' value="Yes" disabled={!formik.isValid} />
                <Link 
                    className='btn bg-success border-0 rounded-2 p-1 ps-2 pe-2 text-white'
                    onClick={clicke}
                    to="#!">
                    No
                </Link>
            </form>
        </div>
  )
}

export default AgendaFormTimeSlot;
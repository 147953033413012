import React, {useState, useEffect} from 'react';
import { Image, Modal, Row, Nav, Col, Tab  } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getAllAgenda } from "../../../features/user/userAction";
import './Agenda.css';
import AgendaFormTimeSlot from './AgendaFormTimeSlot';
import AgendaFormTimeAttend from './AgendaFormTimeAttend';
import AgendaFormSpeakerDel from './AgendaFormSpeakerDel';

const AgendaModalBody = (props) => {
  
  const {addspeaker} = props

  const [isBoxOpen, setIsBoxOpen] = useState(false);
  const [openedItem, setOpenedItem] = useState(false);

  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(getAllAgenda())
  }, [dispatch]);
  
  // We start with an empty list of items.
  const {agenda, user, error} = useSelector((state)=>state.user);
  // BECAUSE OF LOADER IS SHOWING EVERYWHERE
  // if(isLoading) return <h3>Loading ...</h3>;
  if(error) return <h3>{error}</h3>


  return (
    <Modal.Body>
    <Tab.Container id="left-tabs-example"  defaultActiveKey="agenda_0">
        <Row>
            <Col sm={12}>
                <Nav variant="pills" className="d-flex justify-content-center">
                {
                    agenda.distinictdates &&
                    agenda.distinictdates.map((row, i) => (
                        <Nav.Item key={"adminNav"+i}>
                            <Nav.Link className="text-dark" eventKey={"agenda_"+i}>
                                {row.ddate}
                            </Nav.Link>
                        </Nav.Item>
                    ))
                }
                </Nav>
            </Col>
            <Col sm={12}>
                <Tab.Content>
                    {
                    agenda.distinictdates &&
                    agenda.distinictdates.map((row, i) => (
                    <Tab.Pane key={"agendatabcontent"+i} eventKey={"agenda_"+i}>
                        <span className='col-md-3 mt-2 mb-2 d-block text-white text-center bg-black rounded-2 p-3'>
                        {row.ddate}
                        </span>
                        <div className='mt-4'>
                        {
                        agenda.allAgenda &&
                        agenda.allAgenda.map((allrow, i) => (
                            allrow.date == row.ddate ? 
                            <Row className='mb-3'>
                            <Col sm={3}>
                                <div className='d-flex justify-content-start align-items-center h-100 position-relative visible'>
                                    <span className='text-white text-center bg-black rounded-2 p-1 px-3 timeDots'>
                                        {allrow.starttime}
                                    </span>
                                </div>
                            </Col>
                            <Col sm={9} className="position-relative">
                                <div className='p-3 border border-1 rounded-2 position-relative'>
                                    <button onClick={() => setIsBoxOpen(i)} className='border-0 bg-transparent position-absolute text-dark top-0 end-0'>
                                    <i class="bi bi-trash"></i>
                                    </button>
                                    <div className={isBoxOpen === i ? 'd-block border border-2 rounded-2 border-warning w-100 p-1 ps-2 pe-2 mb-3' : 'd-none'}> 
                                        <p className='mb-0 mb-2'>Are you sure want to Delete this time?</p>
                                        <AgendaFormTimeSlot 
                                            ivalue={allrow.id} 
                                            clicke={()=>(setIsBoxOpen(false))}
                                        />

                                    </div>
                                    <div className='d-flex align-items-end justify-content-between'>
                                        <div>
                                            <h3 className='fs-5 fw-bold'>{allrow.title}</h3>
                                            <div className='fs-6 fw-light'>
                                                <i class="bi bi-clock"></i> 
                                                {" "+allrow.starttime} - {allrow.endtime}
                                                <b className='fw-normal'> (GST, UTC+4)</b>
                                            </div>
                                        </div>
                                        {
                                            // agenda.WhoIsAttending &&
                                            // agenda.WhoIsAttending.map((rowhas, i) => (
                                            //     rowhas.agendaid == allrow.id ? <i>1</i> : null
                                            // ))
                                            agenda.WhoIsAttending &&
                                            agenda.WhoIsAttending.filter((allattend, i) => (
                                                allattend.agendaid == allrow.id
                                            )).length ?
                                            <AgendaFormTimeAttend 
                                                useremail={user.email}
                                                agendaid={allrow.id}
                                                ifattend= {
                                                    agenda.WhoIsAttending &&
                                                    agenda.WhoIsAttending.map((allattend, i) => (
                                                        allattend.agendaid == allrow.id ? 1 : null
                                                    ))
                                                }
                                            />
                                            : 
                                            <AgendaFormTimeAttend 
                                                useremail={user.email}
                                                agendaid={allrow.id}
                                                ifattend= '0'
                                            />
                                        }
                                    </div>
                                    <div className="speakersWarp mt-2">

                                    {/* ADD SPEAKER IS COMMENTED IT WILL BE USED IF NEEDED */}
                                    {/* <Link to="#!" onClick={addspeaker}>Add Speaker</Link> */}
                                    
                                    {agenda.Speakers &&
                                        agenda.Speakers.map((allspeak, i) => (
                                        allrow.date == allspeak.speakersdate &&  
                                        allrow.starttime == allspeak.speakerstime
                                        ? 
                                        <>
                                        <Col sm={6}>
                                            <div className="d-flex bg-secondary text-white p-1 rounded-2 position-relative">
                                            <Image src={`../uploads/${allspeak.speakersprofilepic}`} alt="Mini blog"  fluid className='rounded-2'/>
                                            <div className='ms-2'>
                                                {allspeak.speakersname}
                                                <br />
                                                <small>
                                                {allspeak.speakersjobtitle}
                                                </small>
                                                <button onClick={() => setOpenedItem(i)} className='border-0 bg-transparent position-absolute text-white top-0 end-0'>
                                                <i class="bi bi-trash"></i>
                                                </button>
                                                <div className={openedItem === i ? 'd-block border border-2 rounded-2 border-warning w-100 p-1 ps-2 pe-2 mt-3' : 'd-none'}> 
                                                    <p className='mb-0 mb-2'>Are you sure want to Delete this Speaker?</p>
                                                    <AgendaFormSpeakerDel 
                                                        semail={allspeak.speakersemail}
                                                        sid={allspeak.speakersid}
                                                        sboxclose={()=>(setOpenedItem(false))}
                                                    />

                                                </div>
                                            </div>
                                            </div>
                                            
                                        </Col>
                                        </>
                                        
                                        : 
                                        ''
                                        ))
                                    }
                                    </div>
                                </div>
                            </Col>
                                
                            </Row>
                            : 
                            ''
                        ))
                        }
                        </div>
                    </Tab.Pane>
                    ))
                    }
                    {/* {
                        agenda.distinictdates &&
                        agenda.distinictdates.map((row, i) => (
                            <Tab.Pane key={"agendatabcontent"+i} eventKey={"agenda_"+i}>
                                
                                {
                                    agenda.allAgenda &&
                                        agenda.allAgenda.map((allrow, i) => (
                                            allrow.date == row.ddate ? 
                                            <>
                                                <div>Date: {allrow.date}</div>
                                                <div>Title: {allrow.title}</div>
                                                <div>Start Time: {allrow.starttime}</div>
                                                <div>End Time: {allrow.endtime}</div>

                                                {
                                                    agenda.Speakers &&
                                                    agenda.Speakers.map((allspeak, i) => (
                                                        allrow.date == allspeak.speakersdate &&  
                                                        allrow.starttime == allspeak.speakerstime
                                                        ? 
                                                        <>
                                                            <div>Speaker: {allspeak.speakersemail}</div>
                                                        </>
                                                        : 
                                                        ''
                                                    ))
                                                }
                                                <hr />
                                            </>
                                            : 
                                            ''
                                        ))
                                }
                            </Tab.Pane>
                        ))
                    } */}
                </Tab.Content>
            </Col>
        </Row>
    </Tab.Container>
    </Modal.Body>
  )
}

export default AgendaModalBody
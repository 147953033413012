import React, {useEffect} from 'react';
import { Modal, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { getAllResources } from "../../../features/user/userAction";
import ResourcesAddToWallet from './ResourcesAddToWallet';

const ResourcesModalBody = () => {

  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(getAllResources())
  }, [dispatch]);
  
  // We start with an empty tdst of items.
  const {resources, user, error} = useSelector((state)=>state.user);
  // BECAUSE OF LOADER IS SHOWING EVERYWHERE
  // if(isLoading) return <h3>Loading ...</h3>;
  if(error) return <h3>{error}</h3>


  return (
    <Modal.Body>
        <Row>
            <Col sm={12}>
                    <div>
                    <table class="table">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">File Name</th>
                            <th scope="col">Document Type</th>
                            <th className='text-center' scope="col">Download</th>
                            <th className='text-center' scope="col">Add To My Wallet</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            resources.all &&
                            resources.all.map((row, i) => (
                                <tr key={"resources_"+i}>
                                    <td>
                                        {row.id}
                                    </td>
                                    <td>
                                        {row.title}
                                    </td>
                                    <td>
                                        {row.file_type}
                                    </td>
                                    <td className='text-center'>
                                        {
                                            row.file_type === 'doc' ?
                                                <Link 
                                                    to={`../uploads/pdf/${row.file_name}`} 
                                                    target="_blank" 
                                                    download
                                                >
                                                    <i class="bi bi-cloud-download"></i>
                                                </Link>
                                            :
                                                <Link 
                                                    to={`../uploads/mp4/${row.file_name}`} 
                                                    target="_blank" 
                                                    download
                                                >
                                                    <i class="bi bi-cloud-download"></i>
                                                </Link>
                                        }
                                    </td>
                                    <td className='text-center'>
                                        {
                                            //resources.ifuserhas &&
                                            // resources.ifuserhas.map((rowhas, i) => (
                                            //     rowhas.user_resource == row.id ? <i>1</i> : null
                                            // ))
                                            resources.ifuserhas &&
                                            resources.ifuserhas.filter((rowhas, i) => (
                                                rowhas.user_resource === row.id
                                            )).length  ? 
                                            <ResourcesAddToWallet 
                                                userid={row.id}
                                                useremail={user.email}
                                                resourceid={row.id}
                                                ifuhas= {
                                                    resources.ifuserhas &&
                                                    resources.ifuserhas.map((rowhas, i) => (
                                                        rowhas.user_resource === row.id ? '1' : null
                                                    ))
                                                }
                                            />
                                            : 
                                            <ResourcesAddToWallet 
                                                userid={row.id}
                                                useremail={user.email}
                                                resourceid={row.id}
                                                ifuhas= '0'
                                            />
                                        }
                                        
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                        </table>
                       
                    </div>
            </Col>
        </Row>
    </Modal.Body>
  )
}

export default ResourcesModalBody
import React, { useEffect } from "react";
import { resourceToAddToWalletPending, resourceToAddToWalletSuccess, resourceToAddToWalletFail, resourceToAddToWalletResetSuccess } from "../../../features/user/userSlice";
import { resourceToAddToWallet } from '../../../api/userApi';
import { useFormik } from "formik";
import { Alert } from "react-bootstrap";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getAllResources } from "../../../features/user/userAction";


const validationSchema = yup.object({
    useremail: yup
        .string(),
    resourceid: yup
        .string(),
});


const ResourcesAddToWallet = (props) => {

    const {userid, useremail, resourceid, ifuhas} = props;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resourceToAddToWalletResetSuccess());
    }, [dispatch]);

    const { error, successMsg } = useSelector(state => state.user);

    const onSubmit = async (values) => {
        
        //const { ...data } = values;
        const useremail = values.useremail;
        const resourceid = values.resourceid;

        dispatch(resourceToAddToWalletPending());

        try {
            const result = await resourceToAddToWallet({useremail, resourceid});
            // console.log(result);
    
            if(result.status === 'error'){
                return dispatch(resourceToAddToWalletFail(result.message));
            }
    
            dispatch(resourceToAddToWalletSuccess(result));
            dispatch(getAllResources())

        } catch (error) {
            dispatch(resourceToAddToWalletFail(error.message));
        }
    };

    const formik = useFormik({
        initialValues: {
            useremail: useremail,
            resourceid: resourceid,
        },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
        enableReinitialize: true,
    });

    return (
        <div key={"userresources"+userid}>
            
            {/* {isLoading && <Spinner variant="primary" animation="border" />}  */}
            
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <input 
                    type="hidden"
                    name="useremail"
                    value={formik.values.useremail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <input 
                    type="hidden"
                    name="resourceid"
                    value={formik.values.resourceid}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                
                {
                    ifuhas === '0' ? 
                    <button type="submit" className='bg-error border-0 rounded-2 p-1 ps-2 pe-2 text-dark me-2' disabled={!formik.isValid}><i class="bi bi-bag-plus"></i></button>
                    : 
                    <button type="submit" className='bg-success border-0 rounded-2 p-1 ps-2 pe-2 text-white me-2' disabled={!formik.isValid}><i class="bi bi-bag-check"></i></button>
                    
                }
                {error && <Alert variant="danger">{error}</Alert>}
                {successMsg[userid] && <Alert variant="success">{successMsg[userid]}</Alert>}
                
            </form>
        </div>
    )
}

export default ResourcesAddToWallet
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers, getIndividualChat, updateUserStatus, updateUserStatusTo0 } from '../../../features/user/userAction';
import ChatPersonList from './ChatPersonList';
import SinglePersonChat from './SinglePersonChat';
import './ChatMain.css';

const ChatMain = (props) => {

    const {myclass, close, open, fromDashChatHandle, setFromDashChatHandle, chatSrollRef} = props;
    
    // We start with an empty list of items.
    const { user, chatindivid } = useSelector((state)=>state.user);
    
    const dispatch = useDispatch();

    const MINUTE_MS = 12000;

    useEffect(() => {
        dispatch(getAllUsers())
        setInterval(() => {
            dispatch(getIndividualChat())
            dispatch(getAllUsers())
        }, MINUTE_MS);

    }, [dispatch])

    // USER ID
    const myID = user.id  

    const handleOnIdle = (event) => {
        //dispatch(replyOnTicket());console.log('user is idle', event)console.log('last active', getLastActiveTime());console.log('My ID ='+myID)
        dispatch(updateUserStatus(myID));
    };

    // const handleOnActive = event => {console.log('user is active', event);console.log('time remaining', getRemainingTime());console.log('My ID ='+myID)}

    const handleOnAction = (event) => {
        // console.log('user did something', event)console.log('time remaining', getRemainingTime())console.log('My ID ='+myID)
        dispatch(updateUserStatus(myID));
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 3,
        onIdle: handleOnIdle,
        //onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    useEffect(() => {

        // When Windows Close
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', handleTabClosing)
        return () => {
            // Make User SignOut
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', handleTabClosing)
        }

    }, [])

    // CLOSING OF TAB OR WINDOW
    const handleTabClosing = () => {
        dispatch(updateUserStatusTo0())
    }
    const alertUser = () => {
        dispatch(updateUserStatusTo0())
    }

    const [visibleDivIndex, setVisibleDivIndex] = useState('');
    const handleVisibleDiv = (id) => (
        setVisibleDivIndex(id)
    );
    
    

    return (
        <div>
            <ChatPersonList 
                divclass={myclass} 
                clickopen={open} 
                clickclose={close} 
                sendHandleVisibleDiv={handleVisibleDiv}
                resultchat={chatindivid}
                sign_in_user_id={user.id}
            />
            <SinglePersonChat 
                chatFrmDashHandle={fromDashChatHandle}
                chatFrmDashState={setFromDashChatHandle}
                sendVisibleDivIndex={visibleDivIndex} 
                sendSetVisibleDivIndex={handleVisibleDiv}
                resultchat={chatindivid}
                sign_in_user_id={user.id}
                refchat={chatSrollRef}
            />
        </div>
    )
}

export default ChatMain
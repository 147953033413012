import React from 'react';
import { Modal, Button } from "react-bootstrap";
import ProfileModelBody from './ProfileModelBody';

const ProfileModel = (model) => {
  return (
    <div>
      <Modal 
        show={model.show}
        cancel={model.close}
      >
        <Modal.Header>
          <h4 className="text-light m-auto text-center">Profile</h4>
          <Button onClick={model.close}>Close</Button>
        </Modal.Header>

        {/* TO SHOW USER DETAILS IN CHILD COMPONENT */}
        <ProfileModelBody editprofilemodel={model.editprofile} />
        {/* TO SHOW USER DETAILS IN CHILD COMPONENT */}

      </Modal>
    </div>
  )
}

export default ProfileModel
import React from "react";
import { singleMeetingOpenPending, singleMeetingOpenSuccess, singleMeetingOpenFail } from "../../features/user/userSlice";
import { initateMeeting } from '../../api/userApi';
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";


const validationSchema = yup.object({
    mstatus: yup
        .string(),
});

const SideMenuLi = (items) => {
    
    const dispatch = useDispatch();

    const onSubmit = async (values) => {
        
        const mstatus = values.mstatus;
        // console.log(email, password);
        dispatch(singleMeetingOpenPending());

        try {
            const result = await initateMeeting({mstatus});
            // console.log(result);
    
            if(result.status === 'error'){
                return dispatch(singleMeetingOpenFail(result.message));
            }
    
            dispatch(singleMeetingOpenSuccess(result));

        } catch (error) {
            dispatch(singleMeetingOpenFail(error.message));
        }
    };

    const formik = useFormik({
        initialValues: {
          mstatus: '1',
        },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
        enableReinitialize: true,
    });

    return (
      <li>
        <div>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <input 
                    type="hidden"
                    name="mstatus"
                    value={formik.values.mstatus}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <button className="text-uppercase fw-bold  bg-transparent border-0 text-white p" type="submit" disabled={!formik.isValid} >
                  <i className={items.icon}></i>
                  {items.name}
                </button>
            </form>
          </div>
    </li>
    )
}

export default SideMenuLi
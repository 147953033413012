import { getUserPending, getUserSuccess, getUserFail, getAllUserPending, getAllUserSuccess, getAllUserFail, getUserNotePadPending, getUserNotePadSuccess, getUserNotePadFail, getAllAgendaPending, getAllAgendaSuccess, getAllAgendaFail, getAllResourcesPending, getAllResourcesSuccess, getAllResourcesFail, getMeetingPending, getMeetingSuccess, getMeetingFail, getAllChatPending, getAllChatSuccess, getAllChatFail, getAllIndividualChatPending, getAllIndividualChatSuccess, getAllIndividualChatFail, updateUserStatusPending, updateUserStatusSuccess, updateUserStatusFail, updateUserStatusTo0Pending, updateUserStatusTo0Success, updateUserStatusTo0Fail, updateUserChatStatusPending, updateUserChatStatusSuccess, updateUserChatStatusFail, shareBCardPending, shareBCardSuccess, shareBCardFail, sendEmailPending, sendEmailSuccess, sendEmailFail, getAllSurveyPending, getAllSurveySuccess, getAllSurveyFail, sendSurveyPending, sendSurveySuccess, sendSurveyFail, getVideoTokenPending, getVideoTokenSuccess, getVideoTokenFail } from './userSlice';
import { fetchUser, fetchAllUsers, fetchSingleUsersNotePad, fetchAllAgenda, fetchAllResources, fetchMeeting, fetchChat, fetchIndividualChatThunk, updateUserStatusApi, updateUserStatusTo0Api, updateUserChatStatusApi, shareBCardApi, sendEmailApi, fetchSurvey, sendSurveyApi, fetchVideoToken } from '../../api/userApi';

export const getUserProfile = () => async (dispatch) => {
    try {
        dispatch(getUserPending());
        //Call the API
        const result = await fetchUser();
        // console.log(result);
        
        if(result && result.id)
            return dispatch(getUserSuccess(result));

        dispatch(getUserFail("User is not found"));
    } catch (error) {
        dispatch(getUserFail(error));
    }
}

export const getAllUsers = () => async (dispatch) => {
    try {
        dispatch(getAllUserPending());
        //Call the API
        const result = await fetchAllUsers();
        // console.log(result);
        
        if(result)
            return dispatch(getAllUserSuccess(result));

        dispatch(getAllUserFail("Users is not found"));
    } catch (error) {
        dispatch(getAllUserFail(error));
    }
}

export const getUsersNotePad = () => async (dispatch) => {
    try {
        dispatch(getUserNotePadPending());
        //Call the API
        const result = await fetchSingleUsersNotePad();
        // console.log(result);
        
        if(result)
            return dispatch(getUserNotePadSuccess(result));

        dispatch(getUserNotePadFail("User Notepad is not found"));
    } catch (error) {
        dispatch(getUserNotePadFail([]));
    }
}

export const getAllAgenda = () => async (dispatch) => {
    try {
        dispatch(getAllAgendaPending());
        //Call the API
        const result = await fetchAllAgenda();
        // console.log(result);
        
        if(result)
            return dispatch(getAllAgendaSuccess(result));

        dispatch(getAllAgendaFail("Agenda is not found"));
    } catch (error) {
        dispatch(getAllAgendaFail(error));
    }
}

export const getAllResources = () => async (dispatch) => {
    try {
        dispatch(getAllResourcesPending());
        //Call the API
        const result = await fetchAllResources();
        // console.log(result);
        
        if(result)
            return dispatch(getAllResourcesSuccess(result));

        dispatch(getAllResourcesFail("Resources is not found"));
    } catch (error) {
        dispatch(getAllResourcesFail(error));
    }
}

export const getMeeting = () => async (dispatch) => {
    try {
        dispatch(getMeetingPending());
        //Call the API
        const result = await fetchMeeting();
        // console.log(result);
        
        if(result)
            return dispatch(getMeetingSuccess(result));

        dispatch(getMeetingFail("Resources is not found"));
    } catch (error) {
        dispatch(getMeetingFail(error));
    }
}

export const getChat = () => async (dispatch) => {
    try {
        dispatch(getAllChatPending());
        //Call the API
        const result = await fetchChat();
        // console.log(result);
        
        if(result)
            return dispatch(getAllChatSuccess(result));

        dispatch(getAllChatFail("Chat is not found"));
    } catch (error) {
        dispatch(getAllChatFail(error));
    }
}


export const getIndividualChat = () => async (dispatch) => {
    try {
        dispatch(getAllIndividualChatPending());
        //Call the API
        const result = await fetchIndividualChatThunk();
        // console.log(result);
        
        if(result)
            return dispatch(getAllIndividualChatSuccess(result));

        dispatch(getAllIndividualChatFail("Chat is not found"));
    } catch (error) {
        dispatch(getAllIndividualChatFail(error));
    }
}

export const updateUserStatus = (id) => async (dispatch) => {
    dispatch(updateUserStatusPending());
    // Fetch the Data From API
    try{
        const result = await updateUserStatusApi(id);

        // console.log(result.status);
        if(result.status === "error"){
          return dispatch(updateUserStatusFail(result.message));
        }
        //dispatch(fetchSingleTicket(id));
        dispatch(updateUserStatusSuccess(result.message));
        
    } catch(error){
        console.log(error)
        dispatch(updateUserStatusFail(error));
    }
}

export const updateUserStatusTo0 = (id) => async (dispatch) => {
    dispatch(updateUserStatusTo0Pending());
    // Fetch the Data From API
    try{
        const result = await updateUserStatusTo0Api(id);

        // console.log(result.status);
        if(result.status === "error"){
          return dispatch(updateUserStatusTo0Fail(result.message));
        }
        //dispatch(fetchSingleTicket(id));
        dispatch(updateUserStatusTo0Success(result.message));
        
    } catch(error){
        console.log(error)
        dispatch(updateUserStatusTo0Fail(error));
    }
}


export const updateUserChatStatus = (id) => async (dispatch) => {
    dispatch(updateUserChatStatusPending());
    // Fetch the Data From API
    try{
        const result = await updateUserChatStatusApi(id);

        // console.log(result.status);
        if(result.status === "error"){
          return dispatch(updateUserChatStatusFail(result.message));
        }
        //dispatch(fetchSingleTicket(id));
        dispatch(updateUserChatStatusSuccess(result.message));
        
    } catch(error){
        // console.log(error)
        dispatch(updateUserChatStatusFail(error));
    }
}


export const shareBCard = (pid, shareObj) => async (dispatch) => {
    dispatch(shareBCardPending());
    // Fetch the Data From API
    try{
        const result = await shareBCardApi(pid, shareObj);

        // console.log(result.status);
        if(result.status === "error"){
          return dispatch(shareBCardFail(result.message));
        }
        //dispatch(fetchSingleTicket(id));
        dispatch(shareBCardSuccess(result));
        
    } catch(error){
        // console.log(error)
        dispatch(shareBCardFail(error));
    }
}


export const sendEmail = (id, formData) => async (dispatch) => {
    dispatch(sendEmailPending());
    // Fetch the Data From API
    try{
        const result = await sendEmailApi(id, formData);

        // console.log(result.status);
        if(result.status === "error"){
          return dispatch(sendEmailFail(result.message));
        }
        //dispatch(fetchSingleTicket(id));
        dispatch(sendEmailSuccess(result));
        
    } catch(error){
        console.log(error)
        dispatch(sendEmailFail(error));
    }
}

export const getSurvey = () => async (dispatch) => {
    try {
        dispatch(getAllSurveyPending());
        //Call the API
        const result = await fetchSurvey();
        // console.log(result);
        
        if(result)
            return dispatch(getAllSurveySuccess(result));

        dispatch(getAllSurveyFail("Survey is not found"));
    } catch (error) {
        dispatch(getAllSurveyFail(error));
    }
}


export const sendSurvey = (sObj) => async (dispatch) => {
    dispatch(sendSurveyPending());
    // Fetch the Data From API
    try{
        const result = await sendSurveyApi(sObj);

        // console.log(result.status);
        if(result.status === "error"){
          return dispatch(sendSurveyFail(result.message));
        }
        //dispatch(fetchSingleTicket(id));
        dispatch(sendSurveySuccess(result));
        
    } catch(error){
        // console.log(error)
        dispatch(sendSurveyFail(error));
    }
}

export const getVideoToken = () => async (dispatch) => {
    try {
        dispatch(getVideoTokenPending());
        //Call the API
        const result = await fetchVideoToken();
        // console.log(result);
        
        if(result)
            return dispatch(getVideoTokenSuccess(result));

        dispatch(getVideoTokenFail("Survey is not found"));
    } catch (error) {
        dispatch(getVideoTokenFail(error));
    }
}

import React, { useEffect } from 'react';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import { sendSurveyResetSuccess } from '../../../features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getSurvey, sendSurvey } from '../../../features/user/userAction';

const SurveyModelBody = () => {

  // We start with an empty list of Users.
  const { survey, successMsg, error, user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sendSurveyResetSuccess())
    dispatch(getSurvey())
  }, [dispatch])
  
  const handleSubmit = (e) => {
    const option = e.currentTarget.id;
    const memail= user.email;
    const mid = user.id;
    const sObj = {
        option: option,
        email: memail,
        id: mid
    };
    dispatch(sendSurvey(sObj));
    dispatch(getSurvey());
  }

  return (
    <div className='p-3'>
        {error && <Alert variant="danger">{error}</Alert>}
        {successMsg[user.id] && <Alert variant="success">{successMsg[user.id]}</Alert>} 
        <Container>
            {
                survey &&
                survey.map((row, index) => (
                    <Row key={'survey'+index} className="mb-2">
                        <Col sm={4} className="d-flex align-items-center justify-content-start" align="right">
                           <label>{row.label}</label>
                        </Col>
                        <Col sm={6} className="d-flex align-items-center justify-content-start" >
                            <div class="progress w-100">
                                <div 
                                    className={'progress-bar progress-bar-striped '+row.progressbarclass} 
                                    role="progressbar" 
                                    aria-valuenow={row.vote} 
                                    aria-valuemin="0" 
                                    aria-valuemax="100" 
                                    style={{width: `${row.vote}%`}}
                                >
                                    <span className='d-block text-center text-white'>{row.vote} % of users are {row.label}</span>
                                </div>
                            </div>
                        </Col>
                        <Col sm={2}>
                            <Button id={row.label} onClick={handleSubmit}>
                                <i className="bi bi-hand-thumbs-up"></i>
                            </Button>
                        </Col>
                    </Row>
                ))
            }
        </Container>
    </div>
  )
}

export default SurveyModelBody
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './PageNotFound.css';

const PageNotFound = () => {
  return (
    <div className='PageNotFound d-flex justify-content-center align-items-center'>
      <Container className="signUpDiv text-center" >
        <h1 className="text-center text-info text-center">Expo App -  404 Page Not Found</h1>
        <hr />
        <Row>
            <Col>
              <h2>If you are trying to access the dashboard<br />Please <Link className="h2 text-info" to="/">Sign in</Link></h2>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PageNotFound
import React from 'react';
import { useSelector } from 'react-redux';
//import { getIndividualChat } from '../../features/user/userAction';

const Counter = () => {
    const { user, chatindivid } = useSelector((state)=>state.user);
    const totalMessages = 
        chatindivid && 
        chatindivid.filter((a) => (
            a.receiver_id === user.id  
            &&
            a.read_receipt === '0'
        )).length; 
  return (
    <span className="notifications_counter">
        {totalMessages}
    </span>
  )
}

export default Counter
import React from 'react';
import { useSelector } from "react-redux";
import { Modal, Button, Container, Row, Col, Card } from "react-bootstrap";

const ProfileModelBody = (props) => {

  const { editprofilemodel } = props;

  // We start with an empty list of Users.
  const { user, error } = useSelector((state) => state.user);

  return (
    <Modal.Body>
        <Container>
            <Row className="mt-4">
                <Col className="m-auto" md={12}>
                    <Card className="w-100 bg-transparent border-0 ">

                        {/* {isLoading && <h3>Loading ...</h3>} */}
                        {error && <h3>{error}</h3>}

                        <Card.Body className='p-0'>
                            <Card.Text>
                                <p className='text-white'><b>Name:</b> {user.name}</p>
                                <p className='text-white'><b>Phone:</b> {user.phone}</p>
                                <p className='text-white'><b>Email:</b> {user.email}</p>
                                <p className='text-white'><b>Company Name:</b> {user.company}</p>
                                <p className='text-white'><b>Country:</b> {user.country}</p>
                            </Card.Text>
                            <Button onClick={editprofilemodel}>Edit Profile</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </Modal.Body>
  )
}

export default ProfileModelBody
import React from 'react';
import { Modal, Button } from "react-bootstrap";
import VideoCallMain from './VideoCallMain';
// import { getVideoToken } from "../../../features/user/userAction";
// import { useDispatch, useSelector } from 'react-redux';

const VideoCallModel = (model) => {
  const {show, close} = model;
  

  // const mytoken = vtoken.token;
  // const myroom = vtoken.room;

  return (
    <div>
      <Modal 
        show={show}
        cancel={close}
      >
        <Modal.Header>
          <h4 className="text-light m-auto text-center">Video Calling</h4>
          <Button 
            onClick={(e) => (
              close(e)
            )}
          >Close</Button>
        </Modal.Header>

        {/* TO SHOW USER DETAILS IN CHILD COMPONENT */}
        <VideoCallMain  />
        {/* TO SHOW USER DETAILS IN CHILD COMPONENT */}

      </Modal>
    </div>
  )
}

export default VideoCallModel
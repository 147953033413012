import React from "react";
import { insertIndChatPending, insertIndChatSuccess, insertIndChatFail } from "../../../features/user/userSlice";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { userInsertIndChat } from "../../../api/userApi";


const validationSchema = yup.object({
    mymessage: yup
      .string()
      .min(1, "Please add atleast 3 characters")
      .required("Text is required"),
});

const ChatBotForm = (props) => {

    const {myId, whomChattingId } = props;

    const dispatch = useDispatch();

    const onSubmit = async (values) => {
        
        //const { ...data } = values;
        const id = values.id;
        const rid = values.rid;
        const mymessage = values.mymessage;
        // console.log(email, password);
        dispatch(insertIndChatPending());

        try {
            const result = await userInsertIndChat({id, rid, mymessage});
            // console.log(result);
    
            if(result.status === 'error'){
                return dispatch(insertIndChatFail(result));
            }
    
            dispatch(insertIndChatSuccess(result));
            formik.resetForm();
            //dispatch(getChat());

        } catch (error) {
            dispatch(insertIndChatFail(error));
        }
    };

    const formik = useFormik({
        initialValues: {
            id: myId,
            rid: whomChattingId,
            mymessage: '',
        },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
        enableReinitialize: true,
    });

  return (
        <div className="chatFormWrap position-absolute">
            {/* {error && <Alert variant="danger">{error}</Alert>}
            {successMsg[myusersid] && <Alert variant="success">{successMsg[myusersid]}</Alert>} */}
            {/* {isLoading && <Spinner variant="primary" animation="border" />} */}

            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <input 
                    type="hidden"
                    name="id"
                    value={formik.values.id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <input 
                    type="hidden"
                    name="rid"
                    value={formik.values.rid}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <input
                    type="text"
                    name="mymessage"
                    placeholder="Type here..."
                    value={formik.values.mymessage}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={formik.errors.mymessage && formik.touched.mymessage ? 'border-danger messagesInput' : 'border-secondary messagesInput'}
                    style={{minHeight:'50px', fontSize:'14px'}}
                />
                <button type="submit" className="border-0 text-light outline-transparent bg-secondary border-primary" style={{minHeight:'50px'}} disabled={!formik.isValid} >
                    <i className="bi bi-send"></i>
                </button>
            </form>
            {/* <button 
                className="position-absolute border-0 text-light outline-transparent bg-primary border-primary" 
                style={{minHeight:'50px'}} 
                onClick={(e) => (
                    // Same Functions from Diff Components
                    //sendSetVisibleDivIndex(e),
                    btnclick(e)
                )}
            >
                <i className="bi bi-camera-video-fill"></i>
            </button> */}
        </div>
  )
}

export default ChatBotForm;
import React, {useState} from "react";
import Login from '../../components/login/Login';
import { ResetPassword } from "../../components/password-reset/PasswordReset.comp";
import { SignUpForm } from "../../components/signup/SignUp.comp";
import './Home.css';

const Home = () => {

  

  const [frmLoad, setFrmLoad] = useState("login");
  const handleOnResetSubmit = (e) => {
    e.preventDefault();
  };
  const formSwitcher = (frmType) => {
    setFrmLoad(frmType);
  };

  return (
    <div className="entry-page bg-info">
      <div className="bg-light rounded-2 p-5 loginWrap">
        {frmLoad === "login" && (
          <Login
            formSwitcher={formSwitcher}
          />
        )}

        {frmLoad === "rest" && (
          <ResetPassword
            // handleOnChange={handleOnChange}
            handleOnResetSubmit={handleOnResetSubmit}
            formSwitcher={formSwitcher}
            // email={email}
          />
        )}

        {frmLoad === "signup" && (
          <SignUpForm
            // handleOnChange={handleOnChange}
            handleOnResetSubmit={handleOnResetSubmit}
            formSwitcher={formSwitcher}
            // email={email}
          />
        )}
      </div>
    </div>
  )
}

export default Home
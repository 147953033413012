import React, { useEffect } from "react";
import { statusUpdatePending, statusUpdateSuccess, statusUpdateFail, statusUpdateResetSuccess } from "../../../../features/user/userSlice";
import { statusUpdate } from '../../../../api/userApi';
import { useFormik } from "formik";
import { Alert } from "react-bootstrap";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../../../features/user/userAction";


const validationSchema = yup.object({
    id: yup
        .string(),
    islock: yup
        .string()
});

const StatusForm = (statusprops) => {

    const {myusersid, myuserislock} = statusprops;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(statusUpdateResetSuccess());
    }, [dispatch]);

    const { error, successMsg } = useSelector(state => state.user);

    const onSubmit = async (values) => {
        
        //const { ...data } = values;
        const id = values.id;
        const islock = values.islock;
        // console.log(email, password);
        dispatch(statusUpdatePending());

        try {
            const result = await statusUpdate({id, islock});
            // console.log(result);
    
            if(result.status === 'error'){
                return dispatch(statusUpdateFail(result.message));
            }
    
            dispatch(statusUpdateSuccess(result));
            dispatch(getAllUsers());

        } catch (error) {
            dispatch(statusUpdateFail(error.message));
        }
    };

    const formik = useFormik({
        initialValues: {
            id: myusersid,
            islock: myuserislock,
        },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
        enableReinitialize: true,
    });

  return (
        <div key={"adminstatus"+myusersid}>
            {error && <Alert variant="danger">{error}</Alert>}
            {successMsg[myusersid] && <Alert variant="success">{successMsg[myusersid]}</Alert>}
            {/* {isLoading && <Spinner variant="primary" animation="border" />} */}

            <div className={`p-2 mb-2 text-light text-end rounded-2 ${successMsg.usrlock === '0' || myuserislock ==='0' ? 'bg-success' : 'bg-danger'}`}>
                <b className="float-start">Status: </b>   
                <form autoComplete="off" onSubmit={formik.handleSubmit}>
                    <input 
                        type="hidden"
                        name="id"
                        value={formik.values.id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <input 
                        type="hidden"
                        name="islock"
                        value={formik.values.islock}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <input type="submit" className="bg-transparent border-0 text-light outline-transparent" value={successMsg.usrlock === '0' || myuserislock === '0' ? 'Active' : 'Inactive' } disabled={!formik.isValid} />
                </form>
            </div>
        </div>
  )
}

export default StatusForm;
import React from 'react'

const SideMenuLi = (items) => {
  return (
    <li>
        <div onClick={items.click && items.click}>
            {items.ifSpan && items.ifSpan}
            <i className={items.icon}></i>
            {items.name}
        </div>
    </li>
  )
}

export default SideMenuLi
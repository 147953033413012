import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getMeeting, getChat } from '../../../features/user/userAction';
import './InitiateMeeting.css';
import CloseMeetingComp from './CloseMeetingComp';
import ChatBot from './meetingchat/ChatBot';

const InitiateMeeting = () => {
    const { isAuth } = useSelector((state) => state.login);
    const dispatch = useDispatch();
    // We start with an empty list of items.
    const { meeting, user } = useSelector((state)=>state.user);
    const MINUTE_MS = 13000;

    //if(meeting.filter((row) => (row.status == 1) ).length){
        useEffect(() => {
            dispatch(getMeeting());
            dispatch(getChat());
            const interval = setInterval(() => {
                dispatch(getMeeting());
                dispatch(getChat());
            }, MINUTE_MS);
        }, [dispatch])
    //}
    

    return (
        <>
            
        
            <div 
                className={
                    meeting.filter((row, i) => (row.status == 1 )).length ? 'initiateMeeting open' : 'initiateMeeting'
                }
            >
                <div className='initiateMeetingInner'>
                    <iframe
                        src="https://www.youtube.com/embed/tgbNymZ7vqY?enablejsapi=1&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
                    >
                    </iframe>
                </div>
                <ChatBot />
                {
                    isAuth === true ? 
                        user.role === '1' ? 
                            <CloseMeetingComp />
                        : 
                        ''
                    :
                    ''
                }
            </div>
        </>
    )

}

export default InitiateMeeting
import React, { useEffect } from 'react';
import { Modal, Button, Alert } from "react-bootstrap";
import SingleBCardModelBody from './SingleBCardModelBody';
import { shareBCardResetSuccess } from '../../../../features/user/userSlice';
import { shareBCard } from '../../../../features/user/userAction';
import { useDispatch, useSelector } from 'react-redux';

const SingleBCard = (props) => {

  const { show, close, pid, myname, myemail, myjobtitle, myphone, usersname, usersemail } = props;
  const dispatch = useDispatch();

  const { error, successMsg } = useSelector(state => state.user);

  useEffect(() => {
    dispatch(shareBCardResetSuccess())
  }, [dispatch])
  

  const handleOnSubmit = (e) => {
    e.preventDefault();

    const shareObj = {
      myname: myname,
      myemail: myemail,
      myjobtitle: myjobtitle,
      myphone: myphone,
      usersname: usersname,
      usersemail: usersemail,
    }
    dispatch(shareBCard(pid, shareObj));
  };

  return (
    <div className={'bCardWrapper'+pid}>
      <Modal 
        show={show}
        cancel={close}
      >
        <Modal.Header>
          <h5 className="text-dark m-auto text-center">Share Business Card to {usersname}</h5>
          <Button onClick={close}>Close</Button>
        </Modal.Header>

        {/* TO SHOW EDIT USER DETAILS IN CHILD COMPONENT */}
        <SingleBCardModelBody 
          mname={myname} 
          memail={myemail}
          ujobtitle={myjobtitle}
          uphone={myphone}
        />
        {/* TO SHOW EDIT USER DETAILS IN CHILD COMPONENT */}
        <div className="p-3">
          {error && <Alert variant="danger">{error}</Alert>}
          {successMsg[pid] && <Alert variant="success">{successMsg[pid]}</Alert>} 
          <Button className="text-white" onClick={handleOnSubmit} >
            Share to <b>{usersname}</b>
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default SingleBCard
import React from 'react';
import { Modal, Button } from "react-bootstrap";
import SurveyModelBody from './SurveyModelBody';

const SurveyModel = (model) => {
  const {show, close} = model;
  return (
    <div>
      <Modal 
        show={show}
        cancel={close}
        className="w-9vw"
      >
        <Modal.Header>
          <h4 className="text-light m-auto text-center">
            Survey
            <br />
            <small>Your opinion is important to us!</small>
          </h4>
          <Button onClick={close}>Close</Button>
        </Modal.Header>

        {/* TO SHOW SURVEY DETAILS IN CHILD COMPONENT */}
        <SurveyModelBody  />
        {/* TO SHOW SURVEY DETAILS IN CHILD COMPONENT */}

      </Modal>
    </div>
  )
}

export default SurveyModel
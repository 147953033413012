import React from 'react'
import Avatar from 'react-avatar';

const SingleBCardModelBody = (props) => {
  
  const {mname, ujobtitle, uphone} = props;

  return (
    <div>
        
        <div className="bg-info" style={{height: '5px'}}>&nbsp;</div>
        <div className='bg-dark d-flex align-items-center p-3'>
            <Avatar 
                name={mname} 
                size="150"
                round={true}
            />
            <h2 className='mb-4 ps-4 text-white'>
                {mname}
                <span className="d-block" style={{fontSize: '14px'}}>
                    {ujobtitle}
                    <br />
                    <b className='d-block mt-3'>
                        E: {mname}
                        <br />
                        M: {uphone}
                    </b>
                </span>
            </h2>
        </div>
        <div className="bg-info text-dark text-center p-3" style={{fontSize: '18px'}}>
            Powered By: <b>ExpoSite</b>
        </div>
    </div>
  )
}

export default SingleBCardModelBody